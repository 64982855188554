import { Button } from "../../Components/Button";
import { object } from "./functions";
import style from "./DiarioBordo.module.scss";
import Input from "../../Components/Input";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import api from "../../Services/Api";
import { useSelector } from "react-redux";
import { styleGeral } from "../../Styles/Cores";
import { showDynamicToast } from "../../Components/Show-Toast";

export default function DiarioBordo() {
  const infoCheckList = useSelector((state: any) => state.InfoCheckList);
  const permissoes = useSelector((state: any) => state?.whoami?.permissoes);
  const placa = infoCheckList.placa;
  const PlacaAdmin = useSelector((state: any) => state.placa);
  const PlacaEscolhida = permissoes === "ADMIN" ? PlacaAdmin : placa;

  const [stateOperacao, setStateOperacao] = useState("");
  const [carregando, setCarregando] = useState(false);
  const [naoContemChaveStatus, setNaoContemChaveStatus] = useState(false);
  const [atualizarOperacao, setAtualizarOperacao] = useState(0);
  const [erro, setErro] = useState(false);

  let VALIDAR_STATUS = carregando
    ? "VERIFICANDO STATUS..."
    : naoContemChaveStatus
    ? "NÃO CONTÉM STATUS!"
    : erro
    ? "---"
    : stateOperacao;

  useEffect(() => {
    if (PlacaEscolhida) {
      setCarregando(true);
      api
        .get(`/get-status-varredeira?placa=${PlacaEscolhida}`)
        .then((response: any) => {
          const valores = response.data[0]?.valores || [];
          let statusEncontrado = false;

          for (const item of valores) {
            if (item.status) {
              setStateOperacao(item.status);
              setNaoContemChaveStatus(false);
              statusEncontrado = true;
              break;
            }
          }

          if (!statusEncontrado) {
            setStateOperacao("---");
            setNaoContemChaveStatus(true);
          }

          setCarregando(false);
        })
        .catch((e: any) => {
          if (e.response?.status === 400) {
            showDynamicToast("info", e.response.data);
          } else {
            showDynamicToast(
              "error",
              "Erro interno do servidor. Tente novamente após alguns minutos ou contate o suporte se o problema continuar."
            );
          }
          setErro(true);
          setCarregando(false);
        });
    } else {
      showDynamicToast("info", "Por favor, selecione uma placa!");
    }
  }, [PlacaEscolhida, atualizarOperacao]);

  const handleOption = (event: any) => {
    const newValue = event.target.value;
    Popup(newValue);
  };

  const Popup = useCallback(
    (selectedOption: any) => {
      Swal.fire({
        html: `A opção <b>${selectedOption}</b> foi selecionada, deseja confirmar ?`,
        icon: "warning",
        iconColor: styleGeral.Corsecundaria,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: styleGeral.Corsecundaria,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#c2c2c2",
        allowEnterKey: false,
        focusConfirm: false,
        returnFocus: false,
        customClass: {
          confirmButton: style.btnPopup,
          cancelButton: style.btnPopup,
          icon: style.iconPopup,
          popup: style.popup,
          title: style.popupTilte,
        },
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .post(`/set-status-varredeira/${PlacaEscolhida}`, {
              status: selectedOption,
            })
            .then(() => {
              setAtualizarOperacao((oldValue) => oldValue + 1);
              showDynamicToast(
                "success",
                "Status de operação alterado com sucesso!"
              );
            })
            .catch(() => {
              showDynamicToast("error", "Erro ao mudar status da operação!");
            });
        }
      });
    },
    [PlacaEscolhida, stateOperacao]
  );

  return (
    <div className={style.body}>
      <div className={style.navbar}>
        <p className={style.tilte}>
          SELECIONE UM CÓDIGO PARA DEFINIR UM STATUS DA OPERAÇÃO
        </p>
        <div className={style.DivInput}>
          <p className={style.textInput}>STATUS DA OPERAÇÃO:</p>
          <Input
            className="diarioBordo"
            value={VALIDAR_STATUS}
            onChange={handleOption}
          />
        </div>
      </div>
      <div className={style.opcoes}>
        {object.map((dados: any) => (
          <Button
            key={dados.texto}
            onClick={handleOption}
            value={dados.value}
            className={dados.className}
            texto={dados.texto}
          />
        ))}
      </div>
    </div>
  );
}
