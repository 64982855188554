import { useState } from "react";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import style from './InputNovaSenha.module.scss';



export function InputSenha({place, onChange}: any){

    const [showPassword, setShowPassword] = useState(false);
    
      const handleTogglePassword = () => {
        setShowPassword(!showPassword);
      };

    return(
        <div className={style.divSenha}>
      <button
        type="button"
        className={style.icon}
        onClick={handleTogglePassword}
      >
        {showPassword ? (
          <LockOpenOutlinedIcon sx={{ cursor: "pointer" }} />
        ) : (
          <LockOutlinedIcon sx={{ cursor: "pointer" }} />
        )}
      </button>
      <input
        type={showPassword ? "text" : "password"}
        placeholder={place}
        onChange={onChange}
        className={style.senha}
      />
    </div>
    )
}