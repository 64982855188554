import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { ApiVideosInformativos } from "../../Services/Api/Videos-Informativos";
import ListaVideos from "./Components";
import style from "./Videos.module.scss";
import Carregando from "../../Assets/Gif/loading.gif";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

export default function VideosInformativos() {
  const isMobile = useMediaQuery("(max-width: 850px)");
  const isMobileVideo = useMediaQuery("(max-width: 600px)");

  const videoAtual = useSelector((state: any) => state.videoAtual);

  const [todosOsVideos, setTodosOsVideos] = useState<any>(null);
  const [apiError, setApiError] = useState<boolean>(false);

  const [categoriaVideoInicial, setCategoriaVideoInicial] = useState("");
  const [nomeVideoInicial, setNomeVideoInicial] = useState("");
  const [descricaoVideoInicial, setDescricaoVideoInicial] = useState("");

  let caminho: any = todosOsVideos && todosOsVideos[0]?.videos[0]?.filename;
  let regex: any = /videos\/(.*)\//;
  let categoria: any = caminho?.match(regex)[1];

  useEffect(() => {
    const fetchData: any = async () => {
      const result = await ApiVideosInformativos();

      if (result.error) {
        setApiError(true);
      } else {
        setTodosOsVideos(result.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (todosOsVideos) {
      setCategoriaVideoInicial(
        videoAtual === "" ? categoria : videoAtual?.categoria
      );
      setNomeVideoInicial(
        videoAtual === "" ? todosOsVideos[0]?.videos[0]?.nome : videoAtual?.nome
      );
      setDescricaoVideoInicial(
        videoAtual === ""
          ? todosOsVideos[0]?.videos[0]?.descricao
          : videoAtual?.descricao
      );
    }
  }, [videoAtual, todosOsVideos]);

  return (
    <div className={style.agrupamento}>
      {apiError ? (
        <p className={style.semValor}>Erro ao carregar os vídeos</p>
      ) : todosOsVideos ? (
        todosOsVideos.length > 0 ? (
          <>
            <div style={{ margin: "0px 1%" }}>
              <div style={{ display: "flex" }}>
                <p className={style.tituloVideoAtual}>
                  {categoriaVideoInicial}{" "}
                </p>
                <p className={style.tituloVideoAtual}>| {nomeVideoInicial}</p>
              </div>
              <ReactPlayer
                width={isMobile ? "90vw" : "800px"}
                height={isMobileVideo ? "200px" : "400px"}
                url={
                  videoAtual === ""
                    ? todosOsVideos[0]?.videos[0]?.url
                    : videoAtual?.url
                }
                controls={true}
              />
              <p className={style.informacoes}>Informações</p>
              <div className={style.borda} />
              <p className={style.textoInformacoes}>{descricaoVideoInicial}</p>
            </div>

            <div style={{ margin: "1.5%" }}>
              <ListaVideos todosOsVideos={todosOsVideos} />
            </div>
          </>
        ) : (
          <p className={style.semValor}>Nenhum vídeo encontrado</p>
        )
      ) : (
        <img src={Carregando} alt="Carregando" className={style.carregando} />
      )}
    </div>
  );
}
