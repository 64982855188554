
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./Assets/index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import {persistor, store} from "./Redux/Store/index";

const rootElement = document.getElementById("root") ?? document.createElement('div');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
    </PersistGate>
  </Provider>,
);
