import StepIcon from "@mui/material/StepIcon";
import { styleGeral } from "../../Styles/Cores";

export function CustomStepIcon(props: any) {
  const { active, completed, error } = props;

  return (
    <StepIcon
      {...props}
      style={{
        color: active
          ? "#277DD2"
          : completed
          ? styleGeral.Corsecundaria
          : error
          ? "red"
          : "",
      }}
    />
  );
}
