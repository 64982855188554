import { useNavigate } from "react-router";
import erro from "../../Assets/Img/erro404.png";
import style from "./Error.module.scss";

export function Error() {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.body}>
        <p className={style.tilte}>
          Desculpe, esta página não está disponível.
        </p>
        <p className={style.subtilte}>
          O link pode estar indisponível ou a página pode não existir.
        </p>
        <img className={style.img} src={erro} />
        <a className={style.a} href={'/home'}>
          Retornar a página Inicial
        </a>
      </div>
    </>
  );
}
