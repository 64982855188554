export const object = [
  {
    texto: "01 - CHECK LIST",
    value: "01 - CHECK LIST",
    className: "diarioBordo",
  },
  {
    texto: "02 - OPERANDO",
    value: "02 - OPERANDO",
    className: "diarioBordo",
  },
  {
    texto: "03 - AGUARDANDO DEMANDA DO CLIENTE",
    value: "03 - AGUARDANDO DEMANDA DO CLIENTE",
    className: "diarioBordo",
  },
  {
    texto: "04 - INTERVALO",
    value: "04 - INTERVALO",
    className: "diarioBordo",
  },
  {
    texto: "05 - DESLOCAMENTO",
    value: "05 - DESLOCAMENTO",
    className: "diarioBordo",
  },
  {
    texto: "06 - MANUTENÇÃO CORRETIVA",
    value: "06 - MANUTENÇÃO CORRETIVA",
    className: "diarioBordo",
  },
  {
    texto: "07 - REVISÃO PREVENTIVA",
    value: "07 - REVISÃO PREVENTIVA",
    className: "diarioBordo",
  },
  {
    texto: "08 - DESCARTE DE RESÍDUOS",
    value: "08 - DESCARTE DE RESÍDUOS",
    className: "diarioBordo",
  },
  {
    texto: "09 - ABASTECIMENTO DE ÁGUA",
    value: "09 - ABASTECIMENTO DE ÁGUA",
    className: "diarioBordo",
  },
  {
    texto: "10 - ABASTECIMENTO DE COMBUSTÍVEL",
    value: "10 - ABASTECIMENTO DE COMBUSTÍVEL",
    className: "diarioBordo",
  },
  {
    texto: "11 - LUBRIFICAÇÃO E CALIBRAGEM",
    value: "11 - LUBRIFICAÇÃO E CALIBRAGEM",
    className: "diarioBordo",
  },
  {
    texto: "12 - CONDIÇÕES CLIMÁTICAS",
    value: "12 - CONDIÇÕES CLIMÁTICAS",
    className: "diarioBordo",
  },
  {
    texto: "13 - LIMPEZA DO EQUIPAMENTO",
    value: "13 - LIMPEZA DO EQUIPAMENTO",
    className: "diarioBordo",
  },
  {
    texto: "14 - FINALIZADA OPERAÇÃO",
    value: "14 - FINALIZADA OPERAÇÃO",
    className: "diarioBordo",
  },
  {
    texto: "15 - DDS | TREINAMENTO E REUNIÃO",
    value: "15 - DDS | TREINAMENTO E REUNIÃO",
    className: "diarioBordo",
  },
  {
    texto: "16 - AFERIÇÃO E TESTE",
    value: "16 - AFERIÇÃO E TESTE",
    className: "diarioBordo",
  },
  {
    texto: "17 - TROCA DE VASSOURA",
    value: "17 - TROCA DE VASSOURA",
    className: "diarioBordo",
  },
  {
    texto: "18 - TROCA DE PNEU E/OU CONSERTO",
    value: "18 - TROCA DE PNEU E/OU CONSERTO",
    className: "diarioBordo",
  },
];
