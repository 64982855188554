import { useSelector } from "react-redux";
import style from "./Linha.module.scss";
import { useEffect } from "react";

//icons
const Horas =
  require("../../../Assets/Icon/Horas-Trabalhadas/time.png") as string;
const Caminhao =
  require("../../../Assets/Icon/Horas-Trabalhadas/truck.png") as string;
const RPM =
  require("../../../Assets/Icon/Horas-Trabalhadas/motor.png") as string;

let HrsFuncVassouraPrincipal: string | number;
let HrsFuncVassouraEsquerda: string | number;
let HrsFuncVassouraDireita: string | number;
let HrsOperacaoVassouraPrincipal: string | number;
let HrsOperacaoVassouraEsquerda: string | number;
let HrsOperacaoVassouraDireita: string | number;
let RPMEstacionario: string | number;
let RPMCaminhao: string | number;

export const Itens_Menu = () => {
  const dados = useSelector((state: any) => state.dados);

  useEffect(() => {
    if (dados?.length > 0) {
      HrsFuncVassouraPrincipal = "---";
      HrsFuncVassouraEsquerda = "---";
      HrsFuncVassouraDireita = "---";
      HrsOperacaoVassouraPrincipal = "---";
      HrsOperacaoVassouraEsquerda = "---";
      HrsOperacaoVassouraDireita = "---";
      RPMEstacionario = "---";
      RPMCaminhao = "---";
    }
  }, [dados]);

  dados[0]?.valores?.map((response: any) => {
    if (response?.nome === "Horas Funcionamento Vassoura Principal") {
      HrsFuncVassouraPrincipal = response.valor;
    }
    if (response?.nome === "Horas Funcionamento Vassoura Esquerda") {
      HrsFuncVassouraEsquerda = response.valor;
    }
    if (response?.nome === "Horas Funcionamento Vassoura Direita") {
      HrsFuncVassouraDireita = response.valor;
    }
    if (response?.nome === "Horas Operacao Vassoura Principal") {
      HrsOperacaoVassouraPrincipal = response.valor;
    }
    if (response?.nome === "Horas Operacao Vassoura Esquerda") {
      HrsOperacaoVassouraEsquerda = response.valor;
    }
    if (response?.nome === "Horas Operacao Vassoura Direita") {
      HrsOperacaoVassouraDireita = response.valor;
    }
    if (response?.nome === "RPM Motor Estacionario") {
      RPMEstacionario = response.valor;
    }
    if (response?.nome === "RPM Motor Caminhao") {
      RPMCaminhao = response.valor;
    }
  });

  return [
    {
      id: 1,
      icone: (
        <img
          src={Horas}
          alt="Horas Funcionamento Vassoura Principal"
          className={style.img}
        />
      ),
      nome: "Horas Funcionamento Vassoura Principal",
      valor: HrsFuncVassouraPrincipal,
      unidade: "H",
    },
    {
      id: 2,
      icone: (
        <img
          src={Horas}
          alt="Horas Funcionamento Vassoura Esquerda"
          className={style.img}
        />
      ),
      nome: "Horas Funcionamento Vassoura Esquerda",
      valor: HrsFuncVassouraEsquerda,
      unidade: "H",
    },
    {
      id: 3,
      icone: (
        <img
          src={Horas}
          alt="Horas Funcionamento Vassoura Direita"
          className={style.img}
        />
      ),
      nome: "Horas Funcionamento Vassoura Direita",
      valor: HrsFuncVassouraDireita,
      unidade: "H",
    },
    {
      id: 4,
      icone: (
        <img
          src={Caminhao}
          alt="Horas Operacao Vassoura Principal"
          className={style.img}
        />
      ),
      nome: "Horas de Operação da Vassoura Principal",
      valor: HrsOperacaoVassouraPrincipal,
      unidade: "H",
    },
    {
      id: 5,
      icone: (
        <img
          src={Caminhao}
          alt="Horas Operacao Vassoura Esquerda"
          className={style.img}
        />
      ),
      nome: "Horas de Operação da Vassoura Esquerda",
      valor: HrsOperacaoVassouraEsquerda,
      unidade: "H",
    },
    {
      id: 6,
      icone: (
        <img
          src={Caminhao}
          alt="Horas Operacao Vassoura Direita"
          className={style.img}
        />
      ),
      nome: "Horas de Operação da Vassoura Direita",
      valor: HrsOperacaoVassouraDireita,
      unidade: "H",
    },
    {
      id: 7,
      icone: (
        <img src={RPM} alt="RPM Motor Estacionario" className={style.img} />
      ),
      nome: "RPM do Motor Estacionário",
      valor: RPMEstacionario,
      unidade: "rpm",
    },
    {
      id: 8,
      icone: <img src={RPM} alt="RPM Motor Caminhao" className={style.img} />,
      nome: "RPM do Motor do Caminhão",
      valor: RPMCaminhao,
      unidade: "rpm",
    },
  ];
};
