import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import style from "./Template.module.scss";
import Navegacao from "../Navegacao";
import PopupSair from "./functions";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useLocation } from "react-router-dom";

//icons
const Configuracoes =
  require("../../Assets/Icon/Template/config.png") as string;
const Notificacao =
  require("../../Assets/Icon/Template/notificacao.png") as string;
const Usuario = require("../../Assets/Icon/Template/user.png") as string;
const Sair = require("../../Assets/Icon/Template/sair.png") as string;

export default function Template() {
  const dados = useSelector((state: any) => state.usuario);
  const permissoes = useSelector((state: any) => state?.whoami?.permissoes);
  const navigate = useNavigate();
  const permissoesFiltradas = permissoes === "ADMIN" ? permissoes : null;
  const location = useLocation();

  return (
    <>
      <div className={style.header}>
        <Navegacao />
        <div className={style.infosHeader}>
          <p className={style.usuario}>{dados}</p>
          <img src={Usuario} alt="Usuário" className={style.icon} />
          {/* <img src={Notificacao} alt="Notificação" className={style.icon} /> */}
          {permissoesFiltradas === "ADMIN" && (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <img
                    src={Configuracoes}
                    alt="Configurações"
                    {...bindTrigger(popupState)}
                    className={style.icon}
                  />

                  <Menu
                    style={{ marginTop: "5px", marginLeft: "-35px" }}
                    {...bindMenu(popupState)}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/upload-video");
                      }}
                    >
                      Upload Vídeo
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          )}
          {window.location.pathname === "/home" && (
            <div className={style.sair}>
              <button onClick={PopupSair}>
                <img src={Sair} alt="Sair" className={style.sair} />
              </button>
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
}
