import style from "./Linha.module.scss";
import { Itens_Menu } from "./functions";
import { useSelector } from "react-redux";
import { timestampParaDataEHora } from "../../../Services/Utils";

export default function Linhas() {
  let infos = Itens_Menu();
  let UltimaLeitura: any;
  const dados = useSelector((state: any) => state?.dados);

  if (Array.isArray(dados)) {
    dados.map((dado: any) => {
      UltimaLeitura = timestampParaDataEHora(dado?.ts);
    });
  }

  return (
    <div className={style.agrupamento}>
      <div style={{ display: "flex" }}>
        <p className={style.ultimasLeitura}>Última Leitura:</p>
        <p className={style.dataHora}>
          {UltimaLeitura !== undefined ? UltimaLeitura : "---"}
        </p>
      </div>
      {infos?.map((response: any) => (
        <div key={response?.id} className={style?.linha}>
          <div className={style.iconeETexto}>
            {response?.icone}
            <p className={style.texto}>{response.nome}:</p>
          </div>
          <p className={style.texto}>
            {response?.valor ? response?.valor : "---"} {response?.unidade}
          </p>
        </div>
      ))}
    </div>
  );
}
