import api from "..";
import { useDispatch, useSelector } from "react-redux";
import { setInfosHorasTrabalhadas } from "../../../Redux/Actions";

import { useEffect } from "react";

export function ApiHorasTrabalhadas() {
  const dispatch = useDispatch();

  const permissoes = useSelector((state: any) => state?.whoami?.permissoes);
  const infoCheckList = useSelector((state: any) => state.InfoCheckList);
  let placa = infoCheckList.placa;
  const PlacaAdmin = useSelector((state: any) => state.placa);
  const VERIFICAR_PLACA = permissoes === "ADMIN" ? PlacaAdmin : placa;

  useEffect(() => {
    if (placa || PlacaAdmin) {
      api
        .get(`/get-status-varredeira?placa=${VERIFICAR_PLACA}`)
        .then((response: any) => {
          dispatch(setInfosHorasTrabalhadas(response.data));
        })
        .catch(() => {
          dispatch(setInfosHorasTrabalhadas([]));
        });
    }
  }, [PlacaAdmin, placa]);

  return <></>;
}
