import { createStore } from "redux";
import rootReducer from '../Reducers/index';
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import CryptoJS from 'crypto-js';

const encryptionKey = 'chave_varredeiras';

const encryptionTransform = {
  in: (state:any) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state), encryptionKey).toString();
    return { ciphertext };
  },
  out: (state:any) => {
    const bytes = CryptoJS.AES.decrypt(state.ciphertext, encryptionKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plaintext);
  },
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['email', 'senha', 'dados', 'etapa', 'usuario', 'videos', 'videoAtual', 'InfoCheckList', 'whoami', 'placa', 'rows'],
  transforms: [encryptionTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer )

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

 