import { useNavigate } from "react-router-dom";
import { Button } from "../../Components/Button";
import { DataAtual, HoraAtual } from "../../Services/Utils";
import InputSenha from "./Components/Input-Senha";
import { InputUsuario } from "./Components/Input-Usuario";
import style from "./Login.module.scss";
import api from "../../Services/Api";
import { useDispatch, useSelector } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./login.css";
import { useEffect, useState } from "react";
import Carregando from "../../Assets/Gif/loading.gif";
import { styleGeral } from "../../Styles/Cores";
import { showDynamicToast } from "../../Components/Show-Toast";
import { setPlaca } from "../../Redux/Actions";

const Logo = require("../../Assets/Img/logo_gc_brasil.png") as string;

interface params {
  setToken: (token: string) => void;
}

export default function Login(params: params) {
  const email = useSelector((rootReducer: any) => rootReducer.email);
  const senha = useSelector((state: any) => state.senha);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  async function executeLogin() {
    setIsLoading(true);
    try {
      localStorage.clear();
      let body = { login: email, password: senha };
      const res = await api.post("/login", body);

      if (res.data) {
        dispatch(setPlaca(""));
        params.setToken(JSON.stringify(res.data));
      }
    } catch (err: any) {
      setIsLoading(false);

      if (err?.request?.status === 400) {
        showDynamicToast("info", "Preencha todos os campos obrigatórios!");
      } else if (err?.request?.status === 401) {
        showDynamicToast("error", "Usuário/senha inválidos!");
      } else if (err?.request?.status === 402) {
        console.log("primeiro acesso");
      } else if (err?.request?.status === 500) {
        showDynamicToast(
          "error",
          "Login incorreto, por favor preencha todos os campos obrigatórios!"
        );
      }
    }
  }

  return (
    <>
      <div className={style.login}>
        <img src={Logo} className={style.logo} />
        <div className={style.input}>
          <InputUsuario />
          <InputSenha />
          <p
            onClick={() => {
              {
                navigate("/recuperar-senha");
              }
            }}
            className={style.a}
          >
            Esqueceu sua senha?
          </p>
        </div>
        <div className={style.buttonInfo}>
          {isLoading ? (
            <img
              src={Carregando}
              alt="Carregando"
              style={{ width: 80, marginTop: -40 }}
            />
          ) : (
            <Button onClick={executeLogin} className="login" texto="ENTRAR" />
          )}
        </div>
        <div className={style.HoraData}>
          <CalendarMonthIcon />
          <div className={style.espaco}>
            <DataAtual />
          </div>
          <AccessTimeIcon />
          <div className={style.espaco}>
            <HoraAtual />
          </div>
        </div>
      </div>
    </>
  );
}
