import DoneAllIcon from "@mui/icons-material/DoneAll";
import style from "./Mensagem.module.scss";
import { styleGeral } from "../../Styles/Cores";

interface MensagemPros {
  mensagem?: string;
  envio?: string | number;
  enviadoPara?: string;
  recebido?: string | number;
}

export default function Mensagem(props: MensagemPros) {
  const { mensagem, envio, enviadoPara, recebido } = props;

  return (
    <div className={style["content-main"]}>
      <div className={style["content-recebido"]}>
        <p>
          Enviado para:{" "}
          <span
            style={{
              color: "#efb501",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            <b>{enviadoPara}</b>
          </span>
        </p>
      </div>
      <div className={style["content-info"]}>
        <div className={style["content-mensagem"]}>
          <p style={{ color: "black", fontWeight: "bold", fontSize: "14px" }}>
            {mensagem}
          </p>
        </div>
        <DoneAllIcon
          style={{ color: recebido ? "green" : "#9c9c9c", marginRight: "5px" }}
        />
      </div>
      <div className={style["envio"]}>
        <p style={{ color: "white" }}>{envio}</p>
      </div>
    </div>
  );
}
