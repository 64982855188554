import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { CustomStepIcon } from "./functions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import style from "./Recuperar-senha.module.scss";
import { ConfirmarEmail } from "./Components/Confirmar-Email";
import { CriarNovaSenha } from "./Components/Criar-Nova-Senha";
import { useSelector } from "react-redux";
import { AlteradaComSucesso } from "./Components/Alterada-Com-Sucesso";

const Logo = require("../../Assets/Img/logo_gc_brasil.png") as string;

const steps = [
  "Confirmar E-mail",
  "Criar nova senha",
  "Senha Alterada com Sucesso",
];

const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#464444",
        },
        text: {
          fill: "white",
        },
      },
    },
  },
});

export default function RecuperarSenha() {
  const etapa = useSelector((state: any) => state.etapa);

  return (
    <ThemeProvider theme={theme}>
      <img src={Logo} className={style.logo} />
      <Box sx={{ width: "100%", marginTop: "80px" }}>
        <Stepper activeStep={etapa === 2 ? 3 : etapa} alternativeLabel>
          {steps.map((label) => (
            <Step
              sx={{
                ".css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel":
                  {
                    color: "white",
                  },
              }}
              key={label}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {etapa === 0 ? (
          <ConfirmarEmail />
        ) : etapa === 1 ? (
          <CriarNovaSenha />
        ) : etapa === 2 ? (
          <AlteradaComSucesso />
        ) : (
          " "
        )}
      </Box>
    </ThemeProvider>
  );
}
