import Input from "../Input";
import style from "./FiltroData.module.scss";
import { useState } from "react";
import { Button } from "../Button";
import { Paper } from "@mui/material";
import { timestampParaDataEHora } from "../../Services/Utils";
import { styleGeral } from "../../Styles/Cores";

interface FiltroDataProps {
  onFilter: (inicio: string, fim: string) => void;
}

export default function FiltroData({ onFilter }: FiltroDataProps) {
  const [inicio, setInicio] = useState("");
  const [fim, setFim] = useState("");
  const [validar, setValidar] = useState("");

  const handleInicio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInicio(event.target.value);
  };

  const handleFim = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFim(event.target.value);
  };

  const handleFiltrar = () => {
    if (!inicio) {
      setValidar("Por favor, escolha o filtro de início para continuar.");
    } else if (!fim) {
      setValidar("Por favor, escolha o filtro de fim para continuar.");
    } else if (fim < inicio) {
      setValidar("A data de término deve ser posterior à data de início.");
    } else if (Date.parse(fim) > Date.now()) {
      setValidar("A data de fim não pode ser uma data futura.");
    } else {
      setValidar("");
      const inicioTimestamp = Date.parse(inicio) / 1000;
      const fimTimestamp = Date.parse(fim) / 1000;
      onFilter(inicioTimestamp.toString(), fimTimestamp.toString());
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', zIndex: 3}}>
      <div className={style["content"]}>
        <Paper
          sx={{
            background: styleGeral.primaria,
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "420px",
            height: "45px",
            borderRadius: "8px",
          }}
        >
          <div className={style["date"]}>
            <p className={style["text"]}>De</p>
            <Input type="date" className="input-date" onChange={handleInicio} />
            <p className={style["text"]}>à</p>
            <Input type="date" className="input-date" onChange={handleFim} />
            <Button
              texto="Filtrar"
              className="btn-filtrar"
              onClick={handleFiltrar}
            />
          </div>
        </Paper>
      </div>
      <div>
        <p style={{ color: styleGeral.Corsecundaria, position: 'fixed', fontSize: '13px'}}>{validar}</p>
      </div>
    </div>
  );
}
