import moment from "moment";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";

export const DataAtual = () => {
  moment.locale("pt-br");
  return <p>{moment().format("DD/MM/YYYY")}</p>;
};

export const HoraAtual = () => {
  const [horaAtual, setHoraAtual] = useState(moment().format("HH:mm"));

  useEffect(() => {
    const intervalID = setInterval(() => {
      setHoraAtual(moment().format("HH:mm"));
    }, 1000);
    return () => clearInterval(intervalID);
  }, []);

  return <div>{horaAtual}</div>;
};

export function formatDate(timestamp: any) {
  const momentObj = moment(timestamp * 1000);
  const formattedDate = momentObj.format("DD/MM/YYYY");
  return formattedDate;
}

export function tsParaData(timestamp: any) {
  const data = new Date(timestamp * 1000);
  const dataFormatada = data.toLocaleDateString();
  return `${dataFormatada}`;
}

export function getHourFromTimestamp(timestamp: string | number) {
  return moment(timestamp).format("HH:mm:ss");
}

export function TimeZone(timestamp: any) {
  const formattedTime = moment.unix(timestamp).format("HH:mm:ss");
  return formattedTime;
}

export function timestampParaDataEHora(timestamp: any) {
  const data = new Date(timestamp * 1000);
  const dataFormatada = data.toLocaleDateString();
  const horaFormatada = data.toLocaleTimeString();
  return `${dataFormatada} | ${horaFormatada}`;
}

export function gerarTimestampAtual() {
  var timestamp = Date.now().toString();
  return Number(timestamp.substring(0, 10));
}

export function gerarTimestampUmaSemanaAtras() {
  let date = new Date();
  date.setTime(date.getTime() - 7 * 24 * 60 * 60 * 1000);
  date.setHours(0, 0, 0, 0);
  let timestamp = Math.floor(date.getTime() / 1000);
  return timestamp;
}

export default function formatarHora(timestamp: any) {
  const timestampNumerico = parseInt(timestamp, 10);

  if (isNaN(timestampNumerico)) {
    throw new Error("O valor do timestamp não é numérico");
  }

  const hora = DateTime.fromSeconds(timestampNumerico).toFormat("HH:mm");
  return hora;
}

export function convertToJSDate(dateStr: any) {
  const [day, month, year] = dateStr.split("/");
  return new Date(`${year}-${month}-${day}`);
}

export function extrairNomeVideo(str: string) {
  const partes = str.split("/");
  return partes[2].replace(".mp4", "");
}

export function aplicarCapitalize(input: string): string {
  return input?.toLowerCase()?.replace(/\b\w/g, (char) => char?.toUpperCase());
}
