import axios from "axios";

let api: any;

api = axios.create({
  baseURL: "https://backend.gcmonitoramento.com:8443",
});

// MAIN: https://backend.gcmonitoramento.com:8443
// DEV: http://44.210.70.244:8550

api.interceptors.request.use(function (request: any) {
  if (
    window.sessionStorage?.token &&
    JSON.parse(window.sessionStorage?.token).AccessToken
  ) {
    request["headers"]["Authorization"] =
      "Bearer " + JSON.parse(window.sessionStorage?.token).AccessToken;
  }
  return request;
});

api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error.status === 403 || error.response.status === 403) {
      window.location.pathname = "/";
      sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default api;
