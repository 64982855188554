import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { styleGeral } from "../../../../Styles/Cores";
import style from "./Cronometro.module.scss";

function Cronometro() {
  const [segundos, setSegundos] = useState(420);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setSegundos((segundos) => segundos - 1);
    }, 1000);

    if (segundos === 0 && window.location.pathname === "/check-list") {
      clearInterval(interval);
      Swal.fire({
        html: `<b>Tempo Esgotado!</b>
                 <p style="margin-top: 5px">Você será redirecionado...</p>`,
        icon: "warning",
        iconColor: styleGeral.Corsecundaria,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          icon: style.iconPopup,
          popup: style.popupTime,
        },
      });
      setTimeout(() => {
        Swal.close();
        navigate("/iniciar-Check-list");
      }, 4000);
    }

    return () => clearInterval(interval);
  }, [segundos, navigate]);

  const minutos = Math.floor(segundos / 60);
  const segundosRestantes = segundos % 60;

  return (
    <div style={{ fontWeight: 600, marginLeft: "5px" }}>
      {minutos}:{segundosRestantes < 10 ? "0" : ""}
      {segundosRestantes}
    </div>
  );
}

export default Cronometro;
