import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import Checkbox from "@mui/material/Checkbox";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { ExportButton } from "../../../../Components/Export-Excel/ExportButton";
import style from "./ListagemCheckList.module.scss";
import { sxMUI } from "../../StyleMui";
import FiltroData from "../../../../Components/FiltroData";
import {
  gerarTimestampAtual,
  gerarTimestampUmaSemanaAtras,
  tsParaData,
} from "../../../../Services/Utils";
import teste from "../../../Check-List/Turnos.json";
import Input from "../../../../Components/Input";
import { styleGeral } from "../../../../Styles/Cores";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router";
import { routes } from "../../../../Constants/routes";
import {
  IinformacoesBuscar,
  getListarCheckListSimplificado,
} from "../../api/Lista-Check-List-Simplificado";
import api from "../../../../Services/Api";
import { showDynamicToast } from "../../../../Components/Show-Toast";
import { setListagemCheckList } from "../../../../Redux/Actions";
import { CustomNoRowsOverlay } from "../No-Rows";

function ListagemCheckList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let mobile = window.innerWidth < 900;

  const [operador, setOperador] = useState<string>("");
  const [placaEscolhida, setPlacaEscolhida] = useState<string>("");
  const [placasOptions, setPlacasOptions] = useState<ReactNode[]>();
  const [inicio, setInicio] = useState<number>();
  const [fim, setFim] = useState<number>();

  const ULTIMA_BUSCA = useSelector((state: any) => state.rows);
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "ts",
      headerName: "Data",
      ...(mobile ? { width: 180 } : { flex: 1 }),
    },
    {
      field: "contrato",
      headerName: "Contrato",
      ...(mobile ? { width: 180 } : { flex: 1 }),
    },
    {
      field: "operador",
      headerName: "Operador",
      ...(mobile ? { width: 160 } : { flex: 1 }),
    },
    {
      field: "equipamento",
      headerName: "Equipamento",
      ...(mobile ? { width: 180 } : { flex: 1 }),
    },
    {
      field: "placa",
      headerName: "Placa",
      ...(mobile ? { width: 120 } : { flex: 1 }),
    },
    {
      field: "turno",
      headerName: "Turno",
      ...(mobile ? { width: 100 } : { flex: 1 }),
    },
    {
      field: "visualizar",
      headerName: "Visualizar",
      width: 80,
      renderCell: (params) => {
        if (params.row) {
          return (
            <IconButton
              aria-label="download"
              onClick={() => {
                navigate(routes.CHECK_LIST_VIEW(params.row.id));
              }}
            >
              <RemoveRedEyeIcon
                sx={{
                  color: styleGeral.Corsecundaria,
                  borderColor: "transparent",
                  paddingLeft: "10px",
                }}
              />
            </IconButton>
          );
        } else {
          return null;
        }
      },
    },
  ];

  useEffect(() => {
    api
      .get(`/listar-placas-telemetria`)
      .then((response: any) => {
        let arrayPlacas: any = [];
        response.data.map((dados: any) => {
          arrayPlacas.push(dados);
        });
        setPlacasOptions(arrayPlacas);
      })
      .catch(() => {
        showDynamicToast(
          "error",
          "Erro interno do servidor. Tente novamente após alguns minutos ou contate o suporte se o problema continuar."
        );
      });
  }, []);

  const handleFilterData = async (inicio: string, fim: string) => {
    setRows([]);
    dispatch(setListagemCheckList([]));
    setLoading(true);
    let inicioTimestamp = parseInt(inicio);
    let inicioDate = DateTime.fromSeconds(inicioTimestamp).setZone(
      "America/Sao_Paulo",
      { keepLocalTime: true }
    );
    let inicioOffset = inicioDate.offset;
    inicioTimestamp = Math.floor(inicioTimestamp - inicioOffset * 60);

    let fimNumber = parseInt(fim);
    let fimMais4Horas = fimNumber + 4 * 60 * 60;
    let fimTimestamp = fimMais4Horas;
    let fimDate = DateTime.fromSeconds(fimTimestamp, {
      zone: "America/Sao_Paulo",
    }).set({ hour: 23, minute: 59, second: 59 });
    fimTimestamp = Math.floor(fimDate.toSeconds());

    setInicio(inicioTimestamp);
    setFim(fimTimestamp);

    let obj: IinformacoesBuscar = {
      operador: operador,
      placa: placaEscolhida,
      inicio: inicioTimestamp,
      fim: fimTimestamp,
    };

    try {
      const response = await getListarCheckListSimplificado(obj);
      if (response?.status === 200) {
        const formattedRows = response.response.map((row: any) => ({
          ...row,
          ts: row.ts
            ? new Date(row.ts * 1000).toLocaleString("pt-BR", {
                timeZone: "America/Sao_Paulo",
              })
            : "",
        }));
        setRows(formattedRows);
        setLoading(false);
        dispatch(setListagemCheckList(response?.response));
      }
    } catch {
      setLoading(false);
      dispatch(setListagemCheckList([]));
    }
  };

  useEffect(() => {
    const PRIMEIRA_BUSCA = async () => {
      let obj: IinformacoesBuscar = {
        operador: operador,
        placa: placaEscolhida,
        inicio: gerarTimestampUmaSemanaAtras(),
        fim: gerarTimestampAtual(),
      };

      setInicio(gerarTimestampUmaSemanaAtras());
      setFim(gerarTimestampAtual());

      try {
        const response = await getListarCheckListSimplificado(obj);
        if (response?.status === 200) {
          const formattedRows = response.response.map((row: any) => ({
            ...row,
            ts: row.ts
              ? new Date(row.ts * 1000).toLocaleString("pt-BR", {
                  timeZone: "America/Sao_Paulo",
                })
              : "",
          }));
          setRows(formattedRows);
          setLoading(false);
          dispatch(setListagemCheckList(response?.response));
        }
      } catch {
        setLoading(false);
        dispatch(setListagemCheckList([]));
      }
    };

    if (ULTIMA_BUSCA?.length === 0) {
      setLoading(true);
      PRIMEIRA_BUSCA();
    }
  }, []);

  const handleSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPlacaEscolhida(event.target.value);
  };

  function FiltroRelatorio() {
    return (
      <>
        <GridToolbarContainer className={style["content-gridToolbar"]}>
          <div className={style.filtrosDatagrid}>
            <GridToolbarDensitySelector />
            <ExportButton />
          </div>
        </GridToolbarContainer>
        <div className={style["content-gridToolbar-intervalo-pesquisa"]}>
          {inicio && fim ? (
            <>
              <p className={style["content-gridToolbar-intervalo-info"]}>
                <b>Intervalo da pesquisa:</b>
              </p>
              <p className={style["content-gridToolbar-intervalo-data"]}>
                {tsParaData(inicio)} à {tsParaData(fim)}
              </p>
            </>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <div style={{ height: 415, width: "100%" }}>
      <div className={style["content-operador-select-filtroData"]}>
        <Input
          type="text"
          placeholder="Operador"
          value={operador}
          onChange={(e: any) => setOperador(e.target.value)}
          className="operadorListagemCheckList"
        />
        <select
          className={style["selectPlaca"]}
          value={placaEscolhida}
          onChange={handleSelected}
          style={{ zIndex: 3 }}
        >
          <option value="">Selecione uma placa</option>
          {placasOptions?.map((option: any, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <FiltroData onFilter={handleFilterData} />
      </div>

      <div className={style.tabela}>
        <DataGrid
          rows={rows.length > 0 ? rows : ULTIMA_BUSCA}
          columns={columns}
          disableRowSelectionOnClick
          density="compact"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
          components={{
            Toolbar: FiltroRelatorio,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={sxMUI}
        />
      </div>
    </div>
  );
}

export default ListagemCheckList;
