import { useEffect, useState } from "react";
import style from "./Navegacao.module.scss";
import { experimental_sx as sx, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";

//icons
const Home = require("../../Assets/Icon/Template/bx_home-alt-2.png") as string;
const Seta = require("../../Assets/Icon/Template/seta.png") as string;

export default function Navegacao() {
  const [paginaAtual, setPaginaAtual] = useState<any>();
  const isMobile = useMediaQuery("(max-width: 550px)");
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname) {
      let caminhoAjustado = window.location.pathname.replace(
        /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
        ""
      );
      caminhoAjustado = caminhoAjustado
        .replaceAll("-", " ")
        .replaceAll("#", "")
        .replaceAll("/", "");

      setPaginaAtual(caminhoAjustado.trim());
    }
  }, [window.location.pathname]);

  return (
    <div className={style.navegacao}>
      <div
        onClick={() => {
          navigate("/home");
        }}
      >
        <img src={Home} alt="Home" className={style.icon} />
      </div>
      {isMobile ? (
        <p></p>
      ) : (
        <p className={style.pagina}>
          {" "}
          <img className={style.seta} src={Seta} alt="Seta" /> {paginaAtual}
        </p>
      )}
    </div>
  );
}
