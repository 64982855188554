import api from "..";
import { showDynamicToast } from "../../../Components/Show-Toast";

export async function ApiVideosInformativos() {
  try {
    const response = await api.get(`/list-videos`);
    let categorias: any = [];
    let categoriasSeparadas = [];

    response?.data?.forEach((dados: any) => {
      let caminho = dados.filename;
      let regex = /videos\/(.*)\//;
      let categoria = caminho.match(regex)[1];
      if (!categorias.includes(categoria)) {
        categorias.push(categoria);
      }
    });

    for (const categoria of categorias) {
      const response = await api.get(`/list-videos?folder=${categoria}`);
      categoriasSeparadas.push({ categoria, videos: response.data });
    }

    return { data: categoriasSeparadas, error: false };
  } catch (err) {
    showDynamicToast("error", "Erro ao carregar os vídeos!");
    return { data: [], error: true };
  }
}
