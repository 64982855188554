export const setEmail = (email: string | number) => ({
  type: "SET_EMAIL",
  payload: email,
});

export const setSenha = (senha: string | number) => ({
  type: "SET_SENHA",
  payload: senha,
});

export const setEtapa = (etapa : number ) => ({
  type: "SET_ETAPA",
  payload: etapa,
})

export const setConfirmarSenha = (senha : string | number) => ({
  type: "SET_CONFIRMARSENHA",
  payload: senha,
})


export const setInfosHorasTrabalhadas = (dados : any) => ({
  type: "SET_INFOSHORASTRABALHADAS",
  payload: dados,
})

export const setUsuario = (usuario : any) => ({
  type: "SET_USUARIO",
  payload: usuario,
})

export const setVideos = (videos : any) => ({
  type: "SET_VIDEOS",
  payload: videos,
})

export const setVideoAtual = (videoAtual : any) => ({
  type: "SET_VIDEOATUAL",
  payload: videoAtual,
})

export const setCheckList = (InfoCheckList : any) => ({
  type: "SET_CHECKLIST",
  payload: InfoCheckList,
})

export const setWhoami = (whoami : any) => ({
  type: "SET_WHOAMI",
  payload: whoami,
})

export const setPlaca = (placa : any) => ({
  type: "SET_PLACA",
  payload: placa,
})

export const setListagemCheckList = (rows : any) => ({
  type: "SET_LISTAGEMCHECKLIST",
  payload: rows,
})