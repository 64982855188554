import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useState } from 'react';
import style from './Usuario.module.scss';
import { useDispatch } from 'react-redux';
import { setEmail } from '../../../../Redux/Actions'


export function InputUsuario() {

  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const onChange = (e:any) => {
    dispatch(setEmail(e.target.value))
    setValue(e.target.value)
  }

  return (
    <div className={`${style['divUsuario']}`}>
      <PermIdentityIcon className={`${style['icon']}`} />
      <input
        className={`${style['usuario']}`}
        type={"text"}
        value={value}
        onChange={onChange}
        placeholder={"Matricula"}
      ></input>
    </div>
  );
}
