import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import api from "./Services/Api";
import Template from "./Components/Template";
import Home from "./Pages/Home";
import RecuperarSenha from "./Pages/Recuperar-Senha";
import HorasTrabalhadas from "./Pages/Horas-Trabalhadas";
import CheckListConsumo from "./Pages/Check-List-Consumo";
import VideoInformativo from "./Pages/Introducao";
import { useDispatch, useSelector } from "react-redux";
import { setUsuario, setWhoami } from "./Redux/Actions";
import DiarioBordo from "./Pages/Diario-Bordo";
import VideosInformativos from "./Pages/Videos-Informativos";
import CapturaImagem from "./Pages/Captura-Imagem";
import Relatorios from "./Pages/Relatorios";
import { Error } from "./Pages/Error";
import CheckList from "./Pages/Check-List";
import InciarCheckList from "./Pages/Iniciar-Check-List";
import UploadVideo from "./Pages/Upload-Video";
import MensagemTexto from "./Pages/Mensagem-Texto";
import { useNavigate } from "react-router-dom";
import EnvioDeComandos from "./Pages/Envio-de-comandos";
import { showDynamicToast } from "./Components/Show-Toast";
import ListagemCheckList from "./Pages/Listagem-Check-List/components/View";
import { routes } from "./Constants/routes";
import VisualizarCheckList from "./Pages/Listagem-Check-List/components/Visualizar";

export default function Rotas() {
  const [tokenLogin, setTokenLogin] = useState<any>("");
  const [whoamiGlobal, setWhoamiGlobal] = useState<any>({});
  const navigate = useNavigate();

  const dispatch = useDispatch();

  function setToken(token: string) {
    setTokenLogin(token);
    sessionStorage.setItem("token", token);
  }

  useEffect(() => {
    if (tokenLogin !== "") {
      api.get("/whoami").then((res: any) => {
        if (res.status === 200) {
          dispatch(setUsuario(res.data.nome));
          dispatch(setWhoami(res.data));
          setWhoamiGlobal(res.data);
        } else {
          showDynamicToast(
            "error",
            "Erro ao encontrar informações do usuário!"
          );
        }
      });
    }
  }, [tokenLogin]);

  const videoInformativo = useSelector(
    (state: any) => state.whoami?.unwatched_videos
  );

  useEffect(() => {
    if (whoamiGlobal.permissoes === "ADMIN") {
      navigate("/home", {
        replace: false,
        state: { email: whoamiGlobal.email },
      });
    } else if (
      whoamiGlobal.permissoes === "OPERADOR" &&
      videoInformativo?.length > 0
    ) {
      navigate("/video-informativo");
    } else if (
      whoamiGlobal.permissoes === "OPERADOR" &&
      videoInformativo?.length === 0
    ) {
      navigate("/iniciar-Check-list");
    }
  }, [whoamiGlobal]);

  return (
    <Routes>
      <Route path="/" element={<Login setToken={setToken} />} />

      <Route path="/video-informativo" element={<VideoInformativo />} />
      <Route path="/iniciar-Check-list" element={<InciarCheckList />} />
      <Route path="/recuperar-senha" element={<RecuperarSenha />} />
      <Route path="/check-list" element={<CheckList />} />
      <Route path="/" element={<Login setToken={setToken} />} />
      <Route path="/recuperar-senha" element={<RecuperarSenha />} />

      <Route element={<Template />}>
        <Route path="/home" element={<Home />} />
        <Route
          path="/horas-trabalhadas-dos-motores-e-vassouras"
          element={<HorasTrabalhadas />}
        />
        <Route
          path="/check-list-consumo-das-vassouras"
          element={<CheckListConsumo />}
        />
        <Route path="/diario-de-bordo" element={<DiarioBordo />} />
        <Route path="/videos-informativos" element={<VideosInformativos />} />
        <Route path="/upload-video" element={<UploadVideo />} />
        <Route path="/captura-de-imagem" element={<CapturaImagem />} />
        <Route path="/relatorios" element={<Relatorios />} />
        <Route path="/mensagem-de-texto" element={<MensagemTexto />} />
        <Route path="/envio-de-comandos" element={<EnvioDeComandos />} />
        <Route
          path="/listagem-de-check-lists"
          element={<ListagemCheckList />}
        />
        <Route
          path={routes.CHECK_LIST_VIEW(":id")}
          element={<VisualizarCheckList />}
        />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
}
