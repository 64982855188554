import Swal from "sweetalert2";

enum IconType {
  error = "error",
  success = "success",
  warning = "warning",
  info = "info",
  question = "question",
}

interface IconMap {
  [key: string]: IconType;
}

export const showDynamicToast = (
  type: keyof typeof IconType,
  title: string
) => {
  const iconMap: IconMap = {
    error: IconType.error,
    success: IconType.success,
    warning: IconType.warning,
    info: IconType.info,
    question: IconType.question,
  };

  const icon = iconMap[type] || IconType.question;

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon,
    title: title,
  });
};
