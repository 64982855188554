import style from "./UploadVideo.module.scss";
import { Button } from "../../Components/Button/index";
import Input from "../../Components/Input";
import { useState } from "react";
import api from "../../Services/Api";
import gifCarregando from "../../Assets/Gif/loading.gif";
import { showDynamicToast } from "../../Components/Show-Toast";
import { useNavigate } from "react-router-dom";

export default function UploadVideo() {
  const navigate = useNavigate();

  const [folder, setFolder] = useState<string>();
  const [descricao, setDescricao] = useState<string>();
  const [file, setFile] = useState<any>();
  const [ValidarIntroducao, setValidarIntroducao] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const enviar = () => {
    if (folder && descricao && file) {
      setIsLoading(true);

      const form = new FormData();

      const input: any = document.getElementById("arquivo");
      const arquivo: any = input.files[0];

      form.append("my_file", arquivo);

      const formData = new FormData();
      formData.append("file", arquivo);

      api
        .post(
          `/upload-video?folder=${encodeURIComponent(
            folder.toLowerCase()
          )}&descricao=${encodeURIComponent(
            descricao
          )}&inicial=${ValidarIntroducao}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          setIsLoading(false);
          showDynamicToast("success", "Upload do vídeo realizado com sucesso!");
          setTimeout(function () {
            navigate("/home");
          }, 3000);
        })
        .catch(() => {
          setIsLoading(false);
          showDynamicToast("error", "Falha ao realizar upload do vídeo!");
        });
    } else {
      showDynamicToast("info", "Preencha todos os campos obrigatórios!");
    }
  };

  return (
    <div className={style.card}>
      <p className={style.titulo}>Upload de vídeo</p>
      <div className={style.agrupamento}>
        <label htmlFor="pasta" className={style.labelInput}>
          Pasta: <span style={{ color: "red" }}>*</span>
        </label>
        <Input
          id="pasta"
          placeholder="Nome da pasta"
          className="uploadVideo"
          onChange={(e: any) => setFolder(e.target.value)}
        />
        <div
          style={{ display: "flex", alignItems: "baseline", marginTop: "5px" }}
        >
          <Input
            type="checkbox"
            className="checkbox-uploadVideo"
            onChange={(e: any) => setValidarIntroducao(e.target.checked)}
          />
          <label style={{ marginLeft: 10 }} className={style.labelInput}>
            Exibir este vídeo na introdução.
          </label>
        </div>
        <label htmlFor="descricoes" className={style.labelInput}>
          Descrição: <span style={{ color: "red" }}>*</span>
        </label>
        <textarea
          id="descricoes"
          className={style.textArea}
          onChange={(e: any) => setDescricao(e.target.value)}
          placeholder="Descrição do vídeo..."
        />
        <label htmlFor="arquivo" className={style.labelInput}>
          Selecione o vídeo para fazer upload:{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <Input
          type="file"
          id="arquivo"
          className="uploadVideoSelecionarArquivo"
          onChange={(e: any) => setFile(e.target.value)}
        />
      </div>
      {isLoading ? (
        <img src={gifCarregando} alt="Carregando" style={{ width: 80 }} />
      ) : (
        <Button texto="Enviar" onClick={enviar} className="uploadVideo" />
      )}
    </div>
  );
}
