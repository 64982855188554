import style from "./Comandos.module.scss";
import Select from "react-select";
import { useEffect, useState } from "react";
import api from "../../Services/Api";
import { Button } from "../../Components/Button";
import { styleGeral } from "../../Styles/Cores";
import { showDynamicToast } from "../../Components/Show-Toast";

interface Veiculo {
  id?: string;
  local?: string;
  nome?: string;
  placa?: string;
  sistema_id?: string;
  tipo?: string;
}

type OptionType = {
  label: string;
  value: string;
};

export default function EnvioDeComandos() {
  const [placaSelecionada, setPlacaSelecionda] = useState<string>("");
  const [placaSelecionadaTitle, setPlacaSeleciondaTitle] = useState("---");
  const [aviso, setAviso] = useState("");
  const [placas, setPlacas] = useState<string[]>([]);
  const [listagemComandos, setListagemComandos] = useState([]);
  const [comandoSelecionado, setComandoSelecionado] = useState<any>("");
  const [idComando, setIdComando] = useState<any>("");
  const [carregandoComandos, setCarregandoComandos] = useState(false);

  const Enviar = () => {
    api
      .post(
        `/enviar_comandos/${placaSelecionada}/${idComando}/${comandoSelecionado}`
      )
      .then(() => {
        showDynamicToast("success", "Comando enviado com sucesso!");
      })
      .catch(() => {
        showDynamicToast("error", "Erro ao enviar comando!");
      });
  };

  const comandoEscolhido = (option: any) => {
    if (option) {
      setComandoSelecionado(option?.label);
      setIdComando(option?.id);
    }
  };

  let optionsComandos = listagemComandos?.map((modem: any) => ({
    id: modem?.id,
    label: modem?.nome,
    value: modem?.nome,
  }));

  const selectConfigComandos = {
    options: optionsComandos,
    placeholder: "Comandos",
    value:
      optionsComandos && comandoSelecionado
        ? { label: comandoSelecionado, value: comandoSelecionado }
        : null,
    getOptionLabel: (option: OptionType) => option.label,
    getOptionValue: (option: OptionType) => option.value,
  };

  const ListarComandos = () => {
    setCarregandoComandos(true);

    api
      .get(`/listar_comandos?placa=${placaSelecionada}`)
      .then((response: any) => {
        setListagemComandos([]);
        let array: any = [];
        response?.data?.forEach((dados: any) => {
          if (array.includes(dados) === false) {
            array.push(dados);
          }
        });
        setListagemComandos(array);
        setCarregandoComandos(false);
      })
      .catch(() => {
        setListagemComandos([]);
        showDynamicToast("info", "Nenhum comando encontrado!");
        setCarregandoComandos(false);
      });
  };

  useEffect(() => {
    api
      .get(`/listar-placas-telemetria`)
      .then((response: any) => {
        let arrayPlacas: any = [];
        response.data.map((dados: any) => {
          arrayPlacas.push(dados);
        });
        setPlacas(arrayPlacas);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const placaEscolhida = (option: OptionType | null) => {
    if (option) {
      setPlacaSelecionda(option.value);
    }
  };

  const setPlacaEscolhida = () => {
    if (placaSelecionada) {
      setAviso("Placa Selecionada!");
      setPlacaSeleciondaTitle(placaSelecionada);
      ListarComandos();
    } else {
      setAviso("Escolha uma placa!");
    }
  };

  let options = placas?.map((placa: string) => ({
    label: placa,
    value: placa,
  }));

  const selectConfigPermissoes = {
    options: options,
    placeholder: "Placas...",
    value:
      options && placaSelecionada
        ? { label: placaSelecionada, value: placaSelecionada }
        : null,
    getOptionLabel: (option: OptionType) => option.label,
    getOptionValue: (option: OptionType) => option.value,
  };

  return (
    <>
      <div className={style["background-title"]}>
        <div className={style["title"]}>
          Placa selecionada no momento:{" "}
          <span style={{ color: styleGeral.Corsecundaria }}>
            {placaSelecionadaTitle}
          </span>
        </div>
      </div>
      <div className={style["main"]}>
        <div>
          <p className={style["title-select"]}>Selecione uma placa:</p>
          <div className={style["content-select"]}>
            <div>
              <Select
                className={style["select"]}
                {...selectConfigPermissoes}
                onChange={placaEscolhida}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: 14,
                  }),
                }}
                isLoading={placas.length === 0}
                loadingMessage={() => "Carregando..."}
                noOptionsMessage={() => "Nenhuma placa encontrada"}
              />
              <p
                className={`${style["aviso"]} ${
                  aviso.includes("Selecionada") ? style["green"] : style["red"]
                }`}
                style={{
                  position: "fixed",
                  padding: 5,
                  fontSize: "14px",
                }}
              >
                {aviso}
              </p>
            </div>
            <Button
              texto="Selecionar"
              onClick={setPlacaEscolhida}
              className="btn-Selecionar"
            />
          </div>
        </div>

        <div className={style["main-bottom"]}>
          <p className={style["title-select-comandos"]}>
            Selecione um comando:
          </p>
          <div className={style["main-content"]}>
            <Select
              className={style["selectComandos"]}
              {...selectConfigComandos}
              onChange={comandoEscolhido}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: 14,
                }),
              }}
              isLoading={carregandoComandos}
              loadingMessage={() => "Carregando..."}
              noOptionsMessage={() => "Nenhum comando encontrado"}
            />
            <Button
              className="btn-enviar-comandos"
              onClick={Enviar}
              disabled={comandoSelecionado ? false : true}
              texto="Enviar"
            />
          </div>
        </div>
      </div>
    </>
  );
}
