import { useDispatch } from "react-redux";
import { setVideoAtual } from "../../../Redux/Actions";
import style from "./ListaVideos.module.scss";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { extrairNomeVideo } from "../../../Services/Utils";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "16px",
  },
});

const Icone =
  require("../../../Assets/Icon/Videos-Informativos/play.png") as string;

export default function ListaVideos(dados: any) {
  const dispatch = useDispatch();

  dados &&
    dados?.todosOsVideos?.map((response: any) => {
      response?.videos?.map((video: any) => {
        video.nome = extrairNomeVideo(video.filename);
      });
    });

  return (
    <div>
      {dados && dados?.todosOsVideos?.length > 0
        ? dados?.todosOsVideos?.map((response: any, index: any) => (
            <ul key={index} className={style.nav}>
              <li className={style.navItem}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className={style.navLink}
                >
                  <CustomTooltip title={response.categoria}>
                    <a
                      href={`#${response.categoria}`}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {response.categoria}
                    </a>
                  </CustomTooltip>

                  <a href={`#${response.categoria}`}>
                    <img src={Icone} alt="Ícone de vídeo" />
                  </a>
                </div>

                <div id={response.categoria} className={style.navSubmenu}>
                  {response.videos.map((video: any) => (
                    <CustomTooltip title={video.nome} key={video.post_date}>
                      <a
                        href="#"
                        onClick={() => {
                          let infos = {
                            categoria: response.categoria,
                            nome: video.nome,
                            url: video.url,
                            descricao: video.descricao,
                          };
                          dispatch(setVideoAtual(infos));
                        }}
                        className={style.navSubmenuLink}
                        style={{
                          textTransform: "capitalize",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <img src={Icone} alt="Ícone de vídeo" /> {video.nome}
                      </a>
                    </CustomTooltip>
                  ))}
                </div>
              </li>
            </ul>
          ))
        : null}
    </div>
  );
}
