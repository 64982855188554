import { styleGeral } from "../../Styles/Cores";
import style from "./Template.module.scss";
import Swal from "sweetalert2";

export default function PopupSair() {
  return Swal.fire({
    html: `<b>Atenção</b><p style="font-size: 17px;">Tem certeza de que deseja sair da plataforma?</p>`,
    showCancelButton: true,
    confirmButtonText: "Sair",
    confirmButtonColor: styleGeral.Corsecundaria,
    cancelButtonText: "Voltar",
    cancelButtonColor: "#D2D2D2",
    allowEnterKey: false,
    focusConfirm: false,
    returnFocus: false,
    customClass: {
      confirmButton: style.btnPopup,
      cancelButton: style.btnPopup,
      popup: style.popupSair,
    },
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.clear();
      window.location.href = "/";
    }
  });
}
