import { GridColDef } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { styleGeral } from "../../Styles/Cores";

export const columns: GridColDef[] = [
  { field: "name", headerName: "Nome", width: 400 },
  {
    field: "data",
    headerName: "Data",
    width: 180,
  },
  {
    field: "horario",
    headerName: "Horário",
    width: 180,
  },
  {
    field: "baixar",
    headerName: "Baixar",
    width: 220,
    renderCell: (params) => {
      if (params.row.baixar) {
        return (
          <IconButton
            aria-label="download"
            onClick={() => {
              const link = document.createElement("a");
              link.href = params.row.baixar;
              link.setAttribute("download", params.row.baixar);
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            <DownloadIcon
              sx={{
                color: styleGeral.Corsecundaria,
                borderColor: "transparent",
              }}
            />
          </IconButton>
        );
      } else {
        return null;
      }
    },
  },
];
