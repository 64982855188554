import style from "./Senha.module.scss";
import { useState } from "react";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { setSenha } from "../../../../Redux/Actions";
import { useDispatch } from "react-redux";

export default function InputSenha() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const onChange = (e: any) => {
    dispatch(setSenha(e.target.value));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={style.divSenha}>
      <button
        type="button"
        className={style.icon}
        onClick={handleTogglePassword}
      >
        {showPassword ? (
          <LockOpenOutlinedIcon sx={{ cursor: "pointer" }} />
        ) : (
          <LockOutlinedIcon sx={{ cursor: "pointer" }} />
        )}
      </button>
      <input
        type={showPassword ? "text" : "password"}
        placeholder={"Senha"}
        onChange={onChange}
        className={style.senha}
      />
    </div>
  );
}
