import { ChangeEvent, useEffect, useState } from "react";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import style from "./mensagemTexto.module.scss";
import { Button } from "../../Components/Button";
import Mensagem from "../../Components/Mensagem";
import Select from "react-select";
import api from "../../Services/Api";
import { timestampParaDataEHora } from "../../Services/Utils";
import { styleGeral } from "../../Styles/Cores";
import { showDynamicToast } from "../../Components/Show-Toast";

export default function MensagemTexto() {
  const [historicoMensagem, setHistoricoMensagem] = useState<any[]>([]);
  const [reloadHistorico, setReloadHistorico] = useState<any>(false);
  const [destinatario, setDestinatario] = useState<OptionType[]>([]);
  const [selectedDestinatario, setSelectedDestinatario] =
    useState<OptionType>();
  const [titulo, setTitulo] = useState<string>("");
  const [textarea, setTextarea] = useState("");
  const histortico: any = [];
  const [carregando, setCarregando] = useState<boolean>(true);

  useEffect(() => {
    api
      .get(
        `/mensagens/get-historico-notificacoes?filter_message_type=SENT&DESC=True`
      )
      .then((response: any) => {
        response.data?.Items?.map((dados: any) => {
          let obj = {
            from: dados?.from_user?.nome,
            body: dados?.mensagem?.body,
            to: dados?.to_user?.nome,
            recebeu: dados?.received_ts,
            enviado: dados?.sent_ts,
          };
          histortico.push(obj);
        });
        setHistoricoMensagem([...histortico]);
        setReloadHistorico(false);
        setCarregando(false);
      })
      .catch(() => {
        setReloadHistorico(false);
        setCarregando(false);
      });
  }, [reloadHistorico]);

  useEffect(() => {
    api
      .get(`/mensagens/get-usuarios`)
      .then((response: any) => {
        const destinatarios = response.data?.Items?.map((dados: any) => ({
          label: dados.nome,
          value: dados.nome,
          id: dados.id,
        }));
        setDestinatario(destinatarios);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextarea(event.target.value);
  };

  const handleTitle = (e: any) => {
    setTitulo(e.target.value);
  };

  type OptionType = {
    id: any;
    label: string;
    value: string;
  };

  const EscolheDestinatario = (option: OptionType) => {
    setSelectedDestinatario(option);
    return option.value;
  };

  const EnviarMensagem = () => {
    setReloadHistorico(true);
    if (selectedDestinatario && titulo && textarea) {
      const destinatarioId = selectedDestinatario.id;

      let obj = {
        usuario: destinatarioId,
        mensagem: {
          title: titulo,
          body: textarea,
        },
      };
      api
        .post(`/mensagens/enviar-mensagem`, obj)
        .then(() => {
          showDynamicToast("success", "Mensagem enviada com sucesso!");
        })
        .catch(() => {
          showDynamicToast(
            "error",
            "Erro ao enviar a mensagem! Por favor, tente novamente."
          );
        });
    }
  };

  const selectConfigPermissoes = {
    options: destinatario,
    placeholder: "Escolha o destinatário da mensagem",
    getOptionLabel: (destinatario: any) => destinatario.label,
    getOptionValue: (destinatario: any) => destinatario.value,
  };

  return (
    <div className={style["main"]}>
      <div className={style["main-container"]}>
        <div className={style["container-input"]}>
          <Select
            className={style["select"]}
            {...selectConfigPermissoes}
            onChange={EscolheDestinatario}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                fontSize: 14,
              }),
            }}
            isLoading={destinatario.length === 0}
          />
        </div>
        <div className={style["main-content-title"]}>
          <p style={{ color: "white", fontWeight: "bold" }}>Titulo:</p>
          <Input
            placeholder="Exemplo: 'Aviso'"
            onChange={handleTitle}
            className="mensagemTexto-title"
          />
        </div>
        <Textarea
          value={textarea}
          onChange={handleTextareaChange}
          className="mensagemTexto"
          placeholder="Digite sua mensagem..."
        />
        <Button
          onClick={EnviarMensagem}
          texto="Enviar"
          className="EnviarMensagem"
        />
      </div>
      <div className={style["historico"]}>
        <p className={style["historico-title"]}>Histórico de mensagens</p>
        {carregando ? (
          <p style={{ color: "white", fontSize: "15px", fontWeight: "bold" }}>
            Carregando mensagem...
          </p>
        ) : historicoMensagem.length > 0 ? (
          historicoMensagem.map((historico: any, index: number) => (
            <Mensagem
              key={index}
              recebido={historico.recebeu}
              mensagem={historico.body}
              envio={
                historico?.enviado
                  ? timestampParaDataEHora(historico?.enviado)
                  : "00:00"
              }
              enviadoPara={historico.to}
            />
          ))
        ) : (
          <p style={{ color: "white", fontSize: "15px", fontWeight: "bold" }}>
            Sem histórico de mensagem
          </p>
        )}
      </div>
    </div>
  );
}
