import { DataGrid, GridColDef, GridRowsProp, ptBR } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import style from "./PopUp.module.scss";
import react, { useEffect, useState } from "react";
import HorimetroRelatorios from "./Horimetro";
import OutrosRelatorios from "./Outros";

export default function Relatorios() {
  const [typeRelatorio, setTypeRelatorio] = useState("Nenhum");

  useEffect(() => {
    Swal.fire({
      html: `Por favor, escolha o relatório que deseja consultar.`,
      icon: "info",
      iconColor: "#ED3F26",
      showCancelButton: true,
      confirmButtonText: "Horímetro",
      confirmButtonColor: "#ED3F26",
      cancelButtonText: "Outros",
      cancelButtonColor: "#277DD2",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      returnFocus: false,
      customClass: {
        confirmButton: style.btnPopup,
        cancelButton: style.btnPopup,
        icon: style.iconPopup,
        popup: style.popup,
        title: style.popupTilte,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setTypeRelatorio("Horimetro");
      } else {
        setTypeRelatorio("Outros");
      }
    });
  }, []);

  return (
    <>
      {typeRelatorio === "Horimetro" ? (
        <HorimetroRelatorios />
      ) : (
        <OutrosRelatorios />
      )}
    </>
  );
}
