import { styleGeral } from "../../Styles/Cores";

export const sxMUI = {
  //tabela
  backgroundColor: "#272727",
  color: "black",
  border: "none",
  marginLeft: "50px",
  outline: "none",
  marginRight: "50px",
  marginTop: "10px",
  "& .MuiDataGrid-withBorderColor": {
    //bordaDataGrid
    borderColor: "transparent",
  },
  "& .MuiCircularProgress-root": {
    color: styleGeral.Corsecundaria,
  },
  "& .MuiDataGrid-row": {
    //linha
    background: "#fff",
    borderRadius: "8px",
    border: "none",
    marginTop: "5px",
    outline: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "& .MuiDataGrid-withBorderColor": {
      //borda
      borderColor: "transparent",
      outline: "none",
    },
    "& .MuiTablePagination-root": {
      //footer
      color: "white",
      background: "red",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiButton-textSizeSmall": {
      color: "red",
    },
  },
  "& .MuiDataGrid-iconSeparator": {
    //separador colunas
    color: "#272727",
    visibility: "visible",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    //cor titulo tabela
    color: "white",
  },
  // "& .MuiDataGrid-columnHeader": {
  //   background: '#373737',
  // },
  "& .MuiDataGrid-sortIcon": {
    //seta
    color: "#ffff",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-overlay": {
    //fundoTabela
    backgroundColor: "transparent",
  },
  "& .MuiTablePagination-root": {
    //ContadorTabela
    color: "#ffff",
  },
  "& .MuiIconButton-root.Mui-disabled": {
    color: "#9c9c9c",
  },
  "& ..MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiSvgIcon-root": {
    color: styleGeral.Corsecundaria,
  },
  "& .MuiButton-root": {
    color: styleGeral.Cordetalhes,
    fontSize: "13px",
  },
};
