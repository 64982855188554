import TaskAltIcon from "@mui/icons-material/TaskAlt";
import style from "./Alterada.module.scss";
import { styleGeral } from "../../../../Styles/Cores";

export function AlteradaComSucesso() {
  setInterval(() => {
    window.location.pathname = "/";
  }, 6000);

  return (
    <div className={style.body}>
      <p className={style.texto}>Senha alterada com sucesso!</p>
      <p className={style.texto}>
        Aguarde, você será redirecionado para login.
      </p>
      <TaskAltIcon
        className={style.check}
        sx={{ fontSize: "100px", color: styleGeral.Corsecundaria }}
      />
    </div>
  );
}
