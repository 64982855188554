import { DataGrid, ptBR } from "@mui/x-data-grid";
import style from "./CapturaImagem.module.scss";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import api from "../../Services/Api";
import { convertToJSDate, formatDate, TimeZone } from "../../Services/Utils";
import { useSelector } from "react-redux";
import { styleGeral } from "../../Styles/Cores";
import { dataGridStyles } from "./dataGridStyles";
import { columns } from "./columns";
import { showDynamicToast } from "../../Components/Show-Toast";

export default function CapturaImagem() {
  const [linha, setLinha] = useState<Array<any>>([]);
  const [carregando, setCarregando] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const permissoes = useSelector((state: any) => state?.whoami?.permissoes);
  const infoCheckList = useSelector((state: any) => state.InfoCheckList);
  const PlacaAdmin = useSelector((state: any) => state.placa);
  let placa = infoCheckList?.placa;

  const VERIFICAR_PLACA = permissoes === "ADMIN" ? PlacaAdmin : placa;

  let rows = [];

  useEffect(() => {
    api
      .get(`/list-images?placa=${VERIFICAR_PLACA}`)
      .then((response: any) => {
        const novasLinhas = response.data.map((dados: any) => {
          return {
            id: dados.filename,
            name: dados.filename,
            data: formatDate(dados.post_date),
            horario: TimeZone(dados.post_date),
            baixar: dados.url,
            icon: dados.url,
          };
        });

        novasLinhas.sort(
          (a: any, b: any) =>
            convertToJSDate(b.data).getTime() -
            convertToJSDate(a.data).getTime()
        );

        setLinha(novasLinhas);
        setCarregando(false);
      })
      .catch(() => {
        if (placa || PlacaAdmin) {
          showDynamicToast("error", "Erro ao buscar imagens!");
        } else {
          showDynamicToast("info", "Selecione uma placa!");
        }

        setCarregando(false);
      });
  }, []);

  if (carregando) {
    rows = [
      {
        id: "loading",
        name: "Carregando...",
        data: "",
        horario: "",
      },
    ];
  } else {
    rows = linha.filter((row: any) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return (
    <div style={{ height: 415, width: "100%" }}>
      <div className={style.search}>
        <Search sx={{ fontSize: "35px", color: styleGeral.Corsecundaria }} />
        <input
          className={style.searchInput}
          type="text"
          placeholder="Buscar imagem..."
          value={searchTerm}
          onChange={(event: any) => setSearchTerm(event.target.value)}
        />
      </div>
      <div className={style.tabela}>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnMenu
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          sx={dataGridStyles}
        />
      </div>
    </div>
  );
}
