export const Veiculo = [
    {
        "id": 1,
        "key": 1,
        "descricao": "CRLV (documento do Veículo), Validade do Tácografo e Registro ANTT",
    },
    {
        "id": 2,
        "key": 2,
        "descricao": "Disco de tacógrafo em leitura (funcionamento)",
    },
    {
        "id": 3,
        "key": 3,
        "descricao": "Tanque de combustível (nível de combustível, avarias e vazamentos, drenagem da água do filtro separador de combustível) Verificação diária",
    },
    {
        "id": 4,
        "key": 4,
        "descricao": "Vidros: parabrisa, janelas, vidro traseiro (estado e trincas)",
    },
    {
        "id": 5,
        "key": 5,
        "descricao": "Motor (níveis de água e óleo e sem vazamentos, Limpeza do Filtro de Ar do motor)",
    },
    {
        "id": 6,
        "key": 6,
        "descricao": "Iluminação: faróis, lanternas, setas, freio, alerta, farol de ré e lâmpadas strobo (estado e funcionamento)",
    },
    {
        "id": 7,
        "key": 7,
        "descricao": "Cinto de segurança (funcionamento e estado de conservação)",
    },
    {
        "id": 8,
        "key": 8,
        "descricao": "Chave de roda, macaco, triangulo e cones de sinalização (funcionamento e estado)",
    },
    {
        "id": 9,
        "key": 9,
        "descricao": "Portas e trancas (avarias, fucionamento e estado)",
    },
    {
        "id": 10,
        "key": 10,
        "descricao": "Sirene de ré e Buzina (funcionamento)",
    },
    {
        "id": 11,
        "key": 11,
        "descricao": "Extintor (validade, pressurização e estado)",
    },
    {
        "id": 12,
        "key": 12,
        "descricao": "Freios de trabalho e estacionamento (eficiência)",
    },
    {
        "id": 13,
        "key": 13,
        "descricao": "Rodas, pneus dianteiros e traseiros e step (estado, calibragem, fixação e reaperto). Nota: manter fixados e na posição correta os dispositivos indicadores de porca frouxa (DIPs)",
    },
    {
        "id": 14,
        "key": 14,
        "descricao": "Cardan / Cruzetas (estado e lubrificação)",
    },
    {
        "id": 15,
        "key": 15,
        "descricao": "Cabine e chassis (avarias, trincas, limpeza)",
    },
    {
        "id": 16,
        "key": 16,
        "descricao": "Faixa Adesiva para-choque (estado e fixação)",
    },
    {
        "id": 17,
        "key": 17,
        "descricao": "Placas (estado e lacre)",
    },
    {
        "id": 18,
        "key": 18,
        "descricao": "Lubrificação geral (lubrificação toda semana)",
    },
    {
        "id": 19,
        "key": 19,
        "descricao": "Limpeza (lavagem diária e levagem geral toda semana)",
    },
]

export const Varredeira = [
    {
        "id": 20,
        "key": 1,
        "descricao": "Tanque de combustível (nível de combustível, avarias e vazamentos, drenagem da água do filtro separador de combustível) Verificação diária",
    },
    {
        "id": 21,
        "key": 2,
        "descricao": "Acionamento caçamba, tampa traseira e sinais sonoros (estado, avarias e funcionamento)",
    },
    {
        "id": 22,
        "key": 3,
        "descricao": "Iluminação de Segurança: Giroflex, Faróis Vassoura, Seta Sinalizadora (estado e funcionamento)",
    },
    {
        "id": 23,
        "key": 4,
        "descricao": "Caçamba e rotor (avarias, estado e limpeza)",
    },
    {
        "id": 24,
        "key": 5,
        "descricao": "Pistola de alta pressão (estado e funcionamento)",
    },
    {
        "id": 25,
        "key": 6,
        "descricao": "Trava de segurança da caçamba (estado e funcionamento)",
    },
    {
        "id": 26,
        "key": 7,
        "descricao": "Bocal de bicos de aspargimento, vassouras, para-choques (estado, avarias e funcionamento)",
    },
    {
        "id": 27,
        "key": 8,
        "descricao": "Interior caçamba, flaps, telas e divisórias (estado e avarias)",
    },
    {
        "id": 28,
        "key": 9,
        "descricao": "Motor diesel, tanque de água e hidráulico, caixa de engrenagens (níveis de água e óleo, vazamentos)",
    },
    {
        "id": 29,
        "key": 10,
        "descricao": "Mangueiras e sistema hidráulico, pneumático, água do implemento (estado, vazamentos)",
    },
    {
        "id": 30,
        "key": 11,
        "descricao": "Cilindros pneumáticos e hidráulicos (funcionamento, estado, vazamentos)",
    },
    {
        "id": 31,
        "key": 12,
        "descricao": "Suportes de vassouras e bocais (estado, fixação, avarias)",
    },
    {
        "id": 32,
        "key": 13,
        "descricao": "Borrachas (estado, avarias)",
    },
    {
        "id": 33,
        "key": 14,
        "descricao": "Vassouras central e laterais (desgaste, estado e funcionamento) (limite minimo p/ substituição 8 centimentros)",
    },
    {
        "id": 34,
        "key": 15,
        "descricao": "Filtro de Ar do motor estacionário (estado)",
    },
    {
        "id": 35,
        "key": 16,
        "descricao": "Faixas refletivas laterais, adesivo velocidade de operação, etc (estado e fixação)",
    },
]