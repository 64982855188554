import { showDynamicToast } from "../../../../Components/Show-Toast";
import api from "../../../../Services/Api";

export type IinformacoesBuscar = {
  operador?: string;
  placa?: string;
  inicio?: number;
  fim?: number;
};

export const getListarCheckListSimplificado = async (
  params: IinformacoesBuscar
) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined)
  );

  const queryString = new URLSearchParams(filteredParams as any).toString();
  try {
    const response = await api.get(`/listar-check-list?${queryString}`);
    if (response) {
      return { status: 200, response: response.data };
    }
  } catch (error) {
    showDynamicToast("error", "Erro interno do servidor. Tente novamente após alguns minutos ou contate o suporte se o problema continuar.");
    throw error;
  }
};
