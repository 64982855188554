import { DataGrid, GridColDef, GridRowsProp, ptBR } from "@mui/x-data-grid";
import style from "../Relatorios.module.scss";
import { useEffect, useMemo, useState } from "react";
import api from "../../../Services/Api";
import FiltroData from "../../../Components/FiltroData";
import { timestampParaDataEHora, tsParaData } from "../../../Services/Utils";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { sxMUI } from "../stylesMUI";
import { ExportButton } from "../../../Components/Export-Excel/ExportButton";
import { styleGeral } from "../../../Styles/Cores";
import { showDynamicToast } from "../../../Components/Show-Toast";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: "15px",
  },
});

const renameColumns: any = {
  ts: "DATA",
};

export default function HorimetroRelatorios() {
  const [relatorio, setRelatorio] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [inicio, setInicio] = useState<number>();
  const [fim, setFim] = useState<number>();

  let [dataSelecionada, setDataSelecionada] = useState(0);
  let OpcaoRelatorio = "HORÍMETROS DIÁRIO";

  const placa = useSelector((state: any) => state?.placa);

  useEffect(() => {
    if (placa && inicio && fim) {
      setIsLoading(true);
      api
        .get(
          `/gerar_relatorios?placa=${placa}&relatorio=${OpcaoRelatorio}&inicio=${inicio}&fim=${fim}`
        )
        .then((response: any) => {
          setRelatorio(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          showDynamicToast("info", "Intervalo de data sem valores!");
        });
    }
  }, [dataSelecionada]);

  const handleFilterData = (inicio: string, fim: string) => {
    if (placa) {
      setRows([]);
      setColumns([]);
      let inicioTimestamp = parseInt(inicio);
      let inicioDate = DateTime.fromSeconds(inicioTimestamp).setZone(
        "America/Sao_Paulo",
        { keepLocalTime: true }
      );
      let inicioOffset = inicioDate.offset;
      inicioTimestamp = Math.floor(inicioTimestamp - inicioOffset * 60);

      let fimNumber = parseInt(fim);
      let fimMais4Horas = fimNumber + 4 * 60 * 60;
      let fimTimestamp = fimMais4Horas;
      let fimDate = DateTime.fromSeconds(fimTimestamp, {
        zone: "America/Sao_Paulo",
      }).set({ hour: 23, minute: 59, second: 59 });
      fimTimestamp = Math.floor(fimDate.toSeconds());

      setInicio(inicioTimestamp);
      setFim(fimTimestamp);
      setDataSelecionada((prevState) => prevState + 1);
    } else {
      showDynamicToast("info", "Selecione uma placa!");
    }
  };

  function FiltroRelatorio() {
    return (
      <>
        <GridToolbarContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div className={style["div-placa"]}>
            <LocalShippingIcon
              style={{ fontSize: "38px" }}
              titleAccess="Placa selecionada"
              className={style["placa-icon"]}
            />
            <div className={style["content-placa"]}>
              <p className={style["text-placa"]}>Placa</p>
              <p className={style["placa"]}>{placa}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div className={style["div-select-relatorio"]}>
              <select
                className={style["selecionarTipoRelatorio"]}
                disabled={true}
              >
                <option value="">HORÍMETRO</option>
              </select>
            </div>
            <FiltroData onFilter={handleFilterData} />
            <div className={style.filtrosDatagrid}>
              <GridToolbarDensitySelector />
              <ExportButton />
            </div>
          </div>
        </GridToolbarContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          {inicio && fim ? (
            <>
              <p
                style={{
                  color: styleGeral.Corsecundaria,
                  marginBottom: "10px",
                  marginRight: "5px",
                }}
              >
                <b>Intervalo da pesquisa:</b>
              </p>
              <p style={{ color: "white", marginBottom: "10px" }}>
                {tsParaData(inicio)} à {tsParaData(fim)}
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  const extractUniqueKeysFromMock = (relatorio: any[]) => {
    let keysSet = new Set<string>();
    relatorio.forEach((item) => {
      Object.values(item).forEach((array: any) => {
        array.forEach((obj: any) => {
          Object.keys(obj).forEach((key) => keysSet.add(key));
        });
      });
    });
    keysSet.delete("data");
    return Array.from(keysSet);
  };

  const columnKeys = extractUniqueKeysFromMock(relatorio);

  const updatedColumns = columnKeys.map((columnName: string) => {
    const renamedColumn = renameColumns[columnName] || columnName;
    const headerName = renamedColumn.replace(/_/g, " ");

    return {
      field: columnName,
      headerName: headerName?.toUpperCase(),
      width: 350,
      renderCell: (params: any) => {
        const value = params?.value !== undefined ? params?.value : " ";
        const tooltipTitle = String(value);
        return (
          <CustomTooltip title={tooltipTitle}>
            <span
              style={{
                textTransform: "uppercase",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </span>
          </CustomTooltip>
        );
      },
    };
  });

  const colunaSemValor: GridColDef[] = [
    {
      field: "nome",
      headerName: "Intervalo de data sem valores.",
      flex: 1,
    },
  ];

  let tsColumn: any = updatedColumns.find((column) => column?.field === "ts");
  if (!tsColumn) {
    tsColumn = { field: "ts", headerName: "ts", width: 350 };
  }
  const columnsWithoutTs = updatedColumns.filter(
    (column) => column.field !== "ts"
  );

  let finalColumns;
  if (columnsWithoutTs.length === 0) {
    finalColumns = colunaSemValor;
  } else {
    finalColumns = [tsColumn, ...columnsWithoutTs];
  }

  const linhaSemValor: GridRowsProp = [
    { id: "empty_row", nome: "Intervalo de data sem valores." },
  ];

  function compararPorTimestamp(a: any, b: any) {
    return b?.ts - a?.ts;
  }

  const rowsOrdenadas = useMemo(() => {
    if (rows && rows.length > 0) {
      return [...rows].sort(compararPorTimestamp);
    }
    return rows;
  }, [rows]);

  let rowsConvertidas = rowsOrdenadas.map((row: any, index: any) => {
    return {
      id: row.ts,
      ...row,
      ts: row.ts,
    };
  });

  const combineAndSimplifyDataForDate = (data: any) => {
    const resultArray: any[] = [];

    const distinctDatesArray = Array.from(
      new Set(data.map((item: any) => Object.keys(item)[0]))
    );

    distinctDatesArray.forEach((date: any) => {
      const combinedData: any = {};
      combinedData["ts"] = date;

      data.forEach((item: any) => {
        if (item[date]) {
          item[date].forEach((record: any) => {
            for (const key in record) {
              if (key !== "data" && key !== "ts" && !key.includes("HORAS")) {
                combinedData[key] = record[key];
              } else if (key.includes("HORAS")) {
                combinedData[key] = record[key].toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }
            }
          });
        }
      });

      resultArray.push(combinedData);
    });

    setRows(resultArray);
    return resultArray;
  };

  useEffect(() => {
    combineAndSimplifyDataForDate(relatorio);
  }, [relatorio]);

  return (
    <div style={{ height: 415, width: "100%" }}>
      <div className={style.tabela}>
        <DataGrid
          rows={rowsConvertidas?.length > 0 ? rowsConvertidas : linhaSemValor}
          columns={rowsConvertidas?.length > 0 ? finalColumns : colunaSemValor}
          disableRowSelectionOnClick
          loading={isLoading}
          density="compact"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: FiltroRelatorio,
          }}
          sx={sxMUI}
        />
      </div>
    </div>
  );
}
