import style from "./Cards.module.scss";
//icons
const HorasTrabalhadas =
  require("../../../../Assets/Icon/Home/horas-trabalhadas.png") as string;
const CheckList =
  require("../../../../Assets/Icon/Home/checklist.png") as string;
const DiarioDeBordo =
  require("../../../../Assets/Icon/Home/diario-de-bordo.png") as string;
const CapturaDeImagem =
  require("../../../../Assets/Icon/Home/captura-imagem.png") as string;
const VideosInformativos =
  require("../../../../Assets/Icon/Home/videos-informativos.png") as string;
const MensagensDeTexto =
  require("../../../../Assets/Icon/Home/mensagens-texto.png") as string;
const Relatorios =
  require("../../../../Assets/Icon/Home/relatorios.png") as string;
const Comandos = require("../../../../Assets/Icon/Home/comandos.png") as string;
const ListagemCheckList = require("../../../../Assets/Icon/Home/listagemChecklists.png") as string;

export const Itens_Menu = [
  {
    id: 1,
    icone: (
      <img
        src={HorasTrabalhadas}
        alt="HORAS TRABALHADAS DOS MOTORES"
        className={style.img}
      />
    ),
    texto: "HORAS TRABALHADAS DOS MOTORES",
    link: "/horas-trabalhadas-dos-motores-e-vassouras",
  },
  {
    id: 2,
    icone: (
      <img
        src={CheckList}
        alt="CHECK-LIST CONSUMO DAS VASSOURAS"
        className={style.img}
      />
    ),
    texto: "CHECK-LIST CONSUMO DAS VASSOURAS",
    link: "/check-list-consumo-das-vassouras",
  },
  {
    id: 3,
    icone: (
      <img src={DiarioDeBordo} alt="DIÁRIO DE BORDO" className={style.img} />
    ),
    texto: "DIÁRIO DE BORDO",
    link: "/diario-de-bordo",
  },
  {
    id: 4,
    icone: (
      <img
        src={CapturaDeImagem}
        alt="CAPTURA DE IMAGEM"
        className={style.img}
      />
    ),
    texto: "CAPTURA DE IMAGEM",
    link: "/captura-de-imagem",
  },
  {
    id: 5,
    icone: (
      <img
        src={VideosInformativos}
        alt="VÍDEOS INFORMATIVOS"
        className={style.img}
      />
    ),
    texto: "VÍDEOS INFORMATIVOS",
    link: "/videos-informativos",
  },
  {
    id: 6,
    icone: (
      <img
        src={MensagensDeTexto}
        alt="MENSAGEM DE TEXTO"
        className={style.img}
      />
    ),
    texto: "MENSAGEM DE TEXTO",
    link: "/mensagem-de-texto",
  },
  {
    id: 7,
    icone: <img src={Relatorios} alt="RELATÓRIOS" className={style.img} />,
    texto: "RELATÓRIOS",
    link: "/relatorios",
  },
  {
    id: 8,
    icone: <img src={Comandos} alt="ENVIO DE COMANDOS" className={style.img} />,
    texto: "ENVIO DE COMANDOS",
    link: "/envio-de-comandos",
  },
  {
    id: 9,
    icone: <img src={ListagemCheckList} alt="LISTAGEM DE CHECK-LISTS" className={style.img} />,
    texto: "LISTAGEM DE CHECK-LISTS",
    link: "/listagem-de-check-lists",
  },
];
