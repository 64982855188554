import { showDynamicToast } from "../../../../Components/Show-Toast";
import api from "../../../../Services/Api";

export const getListarCheckListPorId = async (id: string | undefined) => {
  try {
    const response = await api.get(`/listar-check-list-por-id/${id}`);

    if (response) {
      return { status: 200, response: response.data };
    }
  } catch (error) {
    showDynamicToast("error", "Erro interno do servidor. Tente novamente após alguns minutos ou contate o suporte se o problema continuar.");
    throw error;
  }
};
