import { ChangeEvent } from "react";
import style from "./Textarea.module.scss";

interface TextareaProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
}

export default function Textarea(props: TextareaProps) {
  const { value, onChange, className = "", placeholder = "" } = props;

  return (
    <textarea
      className={style[className]}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    ></textarea>
  );
}
