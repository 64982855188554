import { styleGeral } from "../../../../Styles/Cores";
import style from "./Verificacoes.module.scss";

interface IItemVerificacao {
  nome: string;
  situacao: string;
  id: number;
}

interface IVerificacoes {
  itens: IItemVerificacao[];
}

const VerificacoesCheckList = ({ itens }: IVerificacoes) => {
  const itensVeiculo = itens?.slice(0, 19);
  const itensVarredeira = itens?.slice(19);

  return (
    <div className={style["content-verificaoes-check-list"]}>
      <div>
        <h3 className={style["content-verificaoes-title"]}>
          Itens de Verificação do Veículo (Caminhão)
        </h3>
        {itensVeiculo?.map((item) => (
          <div
            key={item.id}
            className={style["content-verificacoes-text-situacao"]}
          >
            <p className={style["content-verificacoes-text"]}>{item?.nome}</p>

            <p
              className={style["content-verificacoes-situacao"]}
              style={{
                backgroundColor:
                  item?.situacao === "ok" ? styleGeral.Corsecundaria : "red",
              }}
            >
              {item?.situacao}
            </p>
          </div>
        ))}
      </div>

      <div>
        <h3 className={style["content-verificaoes-title"]}>
          Itens de Verificação da Varredeira
        </h3>
        {itensVarredeira?.map((item) => (
          <div
            key={item.id}
            className={style["content-verificacoes-text-situacao"]}
          >
            <p className={style["content-verificacoes-text"]}>{item?.nome}</p>

            <p
              className={style["content-verificacoes-situacao"]}
              style={{
                backgroundColor:
                  item?.situacao === "ok" ? styleGeral.Corsecundaria : "red",
              }}
            >
              {item?.situacao}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerificacoesCheckList;
