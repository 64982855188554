import style from "./IniciarCheckList.module.scss";
import ErrorIcon from "@mui/icons-material/Error";
import { Button } from "../../Components/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DataAtual, HoraAtual } from "../../Services/Utils";
import { useNavigate } from "react-router";
import { styleGeral } from "../../Styles/Cores";

const Logo = require("../../Assets/Img/logo_gc_brasil.png") as string;

export default function InciarCheckList() {
  const navigate = useNavigate();

  const redirecionarChecklist = () => {
    navigate("/check-list");
  };

  return (
    <div className={style.body}>
      <img className={style.logo} src={Logo} alt="logo varredeiras" />
      <div className={style.efeito}>
        <p className={style.texto}>
          <ErrorIcon
            sx={{
              color: styleGeral.Corsecundaria,
              fontSize: "40px",
              marginRight: "10px",
            }}
          />{" "}
          Deseja iniciar o check-list de operação?
        </p>
        <Button
          onClick={redirecionarChecklist}
          texto="Iniciar"
          className="IniciarCheckList"
        />
      </div>
      <div className={style.HoraData}>
        <CalendarMonthIcon />
        <div className={style.espaco}>
          <DataAtual />
        </div>
        <AccessTimeIcon />
        <div className={style.espaco}>
          <HoraAtual />
        </div>
      </div>
    </div>
  );
}
