import Input from "../../../../Components/Input";
import style from "./Confirmar.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { Button } from "../../../../Components/Button";
import Tooltip from "@mui/material/Tooltip";
import api from "../../../../Services/Api";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setEtapa } from "../../../../Redux/Actions";
import { setEmail } from "../../../../Redux/Actions";
import { styleGeral } from "../../../../Styles/Cores";
import { showDynamicToast } from "../../../../Components/Show-Toast";

let validarDisabled: boolean = true;

export function ConfirmarEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const etapa = useSelector((state: any) => state.etapa);

  const [emailValidar, setEmailValidar] = useState<boolean>(false);
  const [emaill, setEmaill] = useState("");

  dispatch(setEmail(emaill));

  function Voltar() {
    return navigate("/");
  }

  function ForgotPassword() {
    let obj = {
      email: emaill,
    };
    api
      .post(`/forgot-password`, obj)
      .then(() => {
        dispatch(setEtapa(etapa));
      })
      .catch((err: any) => {
        if (err.request.status === 400) {
          showDynamicToast("info", "Preencha todos os campos obrigatórios!");
        } else if (err.request.status === 401) {
          showDynamicToast("error", "Usuário/senha inválidos!");
        } else if (err.request.status === 402) {
          console.log("primeiro acesso");
        } else if (err.request.status === 500) {
          showDynamicToast(
            "error",
            "Quantidade de tentativas foi atingida! Tente novamente mais tarde."
          );
        }
      });
  }

  if (emailValidar === true) {
    validarDisabled = false;
  } else {
    validarDisabled = true;
  }

  const handleEmail = (e: any) => {
    validarEmail(e.target.value);
    setEmaill(e.target.value);
  };

  const validarEmail = (email: any) => {
    let re = /\S+@\S+\.\S+/;
    setEmailValidar(re.test(email));
  };

  return (
    <div className={style.body}>
      <div className={style.input}>
        <p className={style.texto}>Endereço de e-mail</p>
        <div className={style.email}>
          <Input
            onChange={handleEmail}
            type={"text"}
            className={"confirmarEmail"}
            placeholder={"Insira o seu endereço de email"}
          />
          {emailValidar === false ? (
            <CheckIcon
              className={style.validar}
              sx={{ color: "#464444", fontSize: "32px" }}
            />
          ) : (
            <CheckIcon
              className={style.validar}
              sx={{ color: "green", fontSize: "32px" }}
            />
          )}
        </div>
        <div className={style.enviar}>
          <Tooltip title="Voltar">
            <ArrowBackIcon
              onClick={Voltar}
              sx={{ color: "#efb501" }}
              className={style.voltar}
            />
          </Tooltip>
          <Button
            disabled={validarDisabled}
            onClick={ForgotPassword}
            className="validarEmail"
            texto="ENVIAR"
          />
        </div>
      </div>
    </div>
  );
}
