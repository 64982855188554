export const CustomNoRowsOverlay = () => {
  return (
    <div
      style={{
        width: "100%",
        height: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        backgroundColor: "transparent",
      }}
    >
      Nenhuma informação encontrada
    </div>
  );
};
