import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../Components/Button";
import style from "./Cards.module.scss";
import { Itens_Menu } from "./functions";
import Select from "react-select";
import { setListagemCheckList, setPlaca } from "../../../../Redux/Actions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import api from "../../../../Services/Api";
import { showDynamicToast } from "../../../../Components/Show-Toast";

export default function Cards() {
  const [placaSelecionada, setPlacaSelecionda] = useState<any>("");
  const [aviso, setAviso] = useState("");
  const [placas, setPlacas] = useState<string[]>([]);

  const whoami = useSelector((state: any) => state.whoami);
  const PlacaSelecionadaDefault = useSelector((state: any) => state?.placa);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  type OptionType = {
    label: string;
    value: string;
  };

  useEffect(() => {
    if (PlacaSelecionadaDefault) {
      setPlacaSelecionda(PlacaSelecionadaDefault);
    }
  }, [PlacaSelecionadaDefault]);

  useEffect(() => {
    api
      .get(`/listar-placas-telemetria`)
      .then((response: any) => {
        let arrayPlacas: any = [];
        response.data.map((dados: any) => {
          arrayPlacas.push(dados);
        });
        setPlacas(arrayPlacas);
      })
      .catch(() => {
        showDynamicToast("error", "Erro interno do servidor. Tente novamente após alguns minutos ou contate o suporte se o problema continuar.");
      });
  }, []);

  const placaEscolhida = (option: OptionType | null) => {
    if (option) {
      setPlacaSelecionda(option.value);
      localStorage.setItem("placaSelecionada", option.value);
    }
  };

  const setPlacaEscolhida = () => {
    if (placaSelecionada) {
      dispatch(setPlaca(placaSelecionada));
      setAviso("Placa Selecionada!");
      setTimeout(() => {
        setAviso("");
      }, 2000);
    } else {
      setAviso("Escolha uma placa!");
    }
  };

  let options = placas?.map((placa: string) => ({
    label: placa,
    value: placa,
  }));

  const selectConfigPermissoes = {
    options: options,
    placeholder: "Placas...",
    value:
      options && placaSelecionada
        ? { label: placaSelecionada, value: placaSelecionada }
        : null,
    getOptionLabel: (option: OptionType) => option.label,
    getOptionValue: (option: OptionType) => option.value,
  };

  const filteredMenuItems =
    whoami.permissoes === "ADMIN"
      ? Itens_Menu
      : Itens_Menu.filter(
          (item) =>
            item.texto !== "MENSAGEM DE TEXTO" && item.texto !== "RELATÓRIOS" && item.texto !== "LISTAGEM DE CHECK-LISTS"
        );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {whoami.permissoes === "ADMIN" ? (
        <div className={style["main-select"]}>
          <p className={style["title-select"]}>Selecione uma placa:</p>
          <div className={style["content-select"]}>
            <div>
              <Select
                className={style["select"]}
                {...selectConfigPermissoes}
                onChange={placaEscolhida}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: 14,
                  }),
                }}
                isLoading={placas.length === 0}
              />
              <p
                className={`${style["aviso"]} ${
                  aviso.includes("Selecionada") ? style["green"] : style["red"]
                }`}
                style={{
                  position: "fixed",
                  padding: 5,
                  fontSize: "14px",
                }}
              >
                {aviso}
              </p>
            </div>
            <Button
              texto="Selecionar"
              onClick={setPlacaEscolhida}
              className="btn-Selecionar"
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={style.agrupamento}>
        {filteredMenuItems.map((response: any) => (
          <div
            key={response.id}
            className={style.card}
            onClick={() => {
              navigate(response.link);
              dispatch(setListagemCheckList([]));
            }}
          >
            <div className={style.conteudo}>
              {response.icone}
              <p className={style.texto}>{response.texto}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
