import { useNavigate, useParams } from "react-router";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SmartScreenIcon from "@mui/icons-material/SmartScreen";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import TodayIcon from "@mui/icons-material/Today";
import { useEffect, useRef, useState } from "react";
import { getListarCheckListPorId } from "../../api/Listar-Check-List-Completo";
import VerificacoesCheckList from "../Verificacoes";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import isloading from "../../../../Assets/Gif/loading.gif";
import style from "./Visualizar.module.scss";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function VisualizarCheckList() {
  const navigate = useNavigate();
  const { id } = useParams<string>();

  const [response, setResponse] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [showExportButton, setShowExportButton] = useState(true);

  const isMounted = useRef(true);

  useEffect(() => {
    setLoading(true);

    const fetchCheckList = async () => {
      try {
        const response = await getListarCheckListPorId(id);
        if (isMounted.current && response?.status === 200) {
          setResponse(response.response);
          setLoading(false);
        }
      } catch {
        setLoading(false);
        navigate(-1);
      }
    };

    fetchCheckList();

    return () => {
      isMounted.current = false;
    };
  }, [id]);

  const formatDate = (timestamp: number) => {
    if (timestamp) {
      const date = new Date(timestamp * 1000);
      return (
        date.toLocaleDateString("pt-BR") +
        " | " +
        date.toLocaleTimeString("pt-BR")
      );
    }
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const exportToPDF = async () => {
    const domElement = document.getElementById("visualizar-check-list");
    if (domElement) {
      setShowExportButton(false);
      await delay(500);
      const canvas = await html2canvas(domElement, {
        backgroundColor: "#272727",
        scale: 1,
      });

      setShowExportButton(true);
      const imgData = canvas.toDataURL("image/png");

      const margin = 20;
      const pdfWidth = canvas.width + margin * 2;
      const pdfHeight = canvas.height + margin * 2;

      const pdf = new jsPDF({
        orientation: pdfWidth > pdfHeight ? "landscape" : "portrait",
        unit: "px",
        format: [pdfWidth, pdfHeight],
      });

      pdf.setFillColor(39, 39, 39);
      pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

      pdf.addImage(imgData, "PNG", margin, margin, canvas.width, canvas.height);
      pdf.save(
        response
          ? `check-list_${response.placa}_${new Date(response.ts * 1000)
              .toLocaleDateString("pt-BR")
              .replace(/\//g, "-")}`
          : "check-list.pdf"
      );
    }
  };

  const items = [
    {
      icon: <ArticleIcon style={{ color: "white", fontSize: "40px" }} />,
      title: "Contrato",
      value: response.contrato,
    },
    {
      icon: <PersonIcon style={{ color: "white", fontSize: "40px" }} />,
      title: "Operador",
      value: response.operador,
    },
    {
      icon: <LocalShippingIcon style={{ color: "white", fontSize: "40px" }} />,
      title: "Equipamento",
      value: response.equipamento,
    },
    {
      icon: <SmartScreenIcon style={{ color: "white", fontSize: "40px" }} />,
      title: "Placa",
      value: response.placa,
    },
    {
      icon: (
        <AccessTimeFilledIcon style={{ color: "white", fontSize: "40px" }} />
      ),
      title: "Turno",
      value: response.turno,
    },
    {
      icon: <TodayIcon style={{ color: "white", fontSize: "40px" }} />,
      title: "Data",
      value: formatDate(response.ts) || "",
    },
  ];

  return (
    <>
      {loading ? (
        <div className={style["content-loading"]}>
          <img
            src={isloading}
            alt="Carregando"
            className={style["img-loading"]}
          />
        </div>
      ) : (
        <div id="visualizar-check-list">
          <div className={style["content-informacoes"]}>
            {items.map((item, index) => (
              <div
                key={index}
                className={style["content-informacoes-icon-title-value"]}
              >
                {item.icon}
                <div>
                  <p className={style["content-informacoes-title"]}>
                    {item.title}
                  </p>
                  <p className={style["content-informacoes-value"]}>
                    {item.value}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div
            id="exportar-pdf"
            className={style["content-export-text-icon-pdf"]}
            style={{ display: showExportButton ? "flex" : "none" }}
          >
            <p className={style["content-export-text"]}>Exportar em PDF</p>
            <UploadFileIcon
              className={style["content-export-icon"]}
              style={{
                fontSize: "34px",
              }}
              onClick={exportToPDF}
            />
          </div>

          <VerificacoesCheckList itens={response?.itens_verificacao} />
        </div>
      )}
    </>
  );
}

export default VisualizarCheckList;
