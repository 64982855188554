import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Button } from "../../Components/Button";
import style from "./Video.module.scss";
import { experimental_sx as sx, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import api from "../../Services/Api";
import { showDynamicToast } from "../../Components/Show-Toast";

export default function VideoInformativo() {
  const isMobile = useMediaQuery("(max-width: 910px)");
  const [disabled, setDisabled] = useState(true);
  const video = useSelector(
    (state: any) => state.whoami?.unwatched_videos[0]?.url
  );
  const descricao = useSelector(
    (state: any) => state.whoami?.unwatched_videos[0]?.descricao
  );

  function HabilitarBotao() {
    let obj = {
      video_key: {
        url: video,
        descricao: descricao,
        inicial: "true",
      },
    };

    api
      .post(`/set-watched-videos`, obj)
      .then(() => setDisabled(false))
      .catch(() => {
        showDynamicToast("error", "Falha ao validar vídeo como assistido!");
      });
  }

  return (
    <div className={style.agrupamento}>
      <div className={style.contentTitle}>
        <p className={style.titulo}>Vídeo Informativo</p>
      </div>
      <ReactPlayer
        width={isMobile ? "90vw" : "900px"}
        height={"400px"}
        url={video}
        controls={true}
        playing={true}
        onEnded={HabilitarBotao}
      />
      <div className={style.mensagemEBotao}>
        <p className={style.mensagem}>
          Você poderá continuar após o final do vídeo...
        </p>
        <Button
          texto="Continue"
          onClick={() => {
            window.location.href = "/iniciar-Check-list";
          }}
          className="videoInformativo"
          disabled={disabled}
        />
      </div>
    </div>
  );
}
