
import { BrowserRouter } from 'react-router-dom';
import Rotas from './Routes';


function App() {

  return (
    < BrowserRouter >
      <Rotas />
    </BrowserRouter>
  );
}

export default App;
