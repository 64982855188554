import { DataGrid, GridColDef, GridRowsProp, ptBR } from "@mui/x-data-grid";
import style from "../Relatorios.module.scss";
import { useEffect, useMemo, useState } from "react";
import api from "../../../Services/Api";
import FiltroData from "../../../Components/FiltroData";
import { timestampParaDataEHora, tsParaData } from "../../../Services/Utils";
import { useMediaQuery } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { styleGeral } from "../../../Styles/Cores";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import Checkbox from "@mui/material/Checkbox";
import { sxMUI } from "../stylesMUI";
import { ExportButton } from "../../../Components/Export-Excel/ExportButton";
import { showDynamicToast } from "../../../Components/Show-Toast";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: "15px",
  },
});

const renameColumns: any = {
  ts: "DATA",
};

export default function OutrosRelatorios() {
  const [relatorio, setRelatorio] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [somenteInicioFim, setSomenteInicioFim] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [columns, setColumns] = useState<any>([]);
  const [inicio, setInicio] = useState<number>();
  const [fim, setFim] = useState<number>();

  let [dataSelecionada, setDataSelecionada] = useState(0);

  const whoami = useSelector((state: any) => state?.whoami);
  const placa = useSelector((state: any) => state?.placa);

  const options = whoami?.relatorios;

  const handleSelected = (event: React.ChangeEvent<any>) => {
    if (placa) {
      setSelectedValue(event.target.value as string);
    } else {
      showDynamicToast("info", "Selecione uma placa!");
    }
  };

  useEffect(() => {
    if (placa && selectedValue && inicio && fim) {
      setIsLoading(true);
      api
        .get(
          `/gerar_relatorios?placa=${placa}&relatorio=${selectedValue}&inicio=${inicio}&fim=${fim}`
        )
        .then((response: any) => {
          setRelatorio(response.data);
          setIsLoading(false);
        })
        .catch((erro: any) => {
          setIsLoading(false);
          showDynamicToast("info", "Intervalo de data sem valores!");
        });
    } else {
      showDynamicToast(
        "info",
        "Por favor, preencha todos os campos obrigatórios!"
      );
      setInicio(0);
      setFim(0);
    }
  }, [dataSelecionada]);

  const handleFilterData = (inicio: string, fim: string) => {
    setRows([]);
    setColumns([]);
    let inicioTimestamp = parseInt(inicio);
    let inicioDate = DateTime.fromSeconds(inicioTimestamp).setZone(
      "America/Sao_Paulo",
      { keepLocalTime: true }
    );
    let inicioOffset = inicioDate.offset;
    inicioTimestamp = Math.floor(inicioTimestamp - inicioOffset * 60);

    let fimNumber = parseInt(fim);
    let fimMais4Horas = fimNumber + 4 * 60 * 60;
    let fimTimestamp = fimMais4Horas;
    let fimDate = DateTime.fromSeconds(fimTimestamp, {
      zone: "America/Sao_Paulo",
    }).set({ hour: 23, minute: 59, second: 59 });
    fimTimestamp = Math.floor(fimDate.toSeconds());

    setInicio(inicioTimestamp);
    setFim(fimTimestamp);
    setDataSelecionada((dataSelecionada += 1));
  };

  const relatorioComUnderscores = relatorio.map((item: any) => {
    const itemComUnderscores: any = {};

    for (const key in item) {
      const newKey = key.replace(/ /g, "_");
      itemComUnderscores[newKey] = item[key];
    }

    return itemComUnderscores;
  });

  function FiltroRelatorio() {
    return (
      <>
        <GridToolbarContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div className={style["div-placa"]}>
            <LocalShippingIcon
              style={{ fontSize: "38px" }}
              titleAccess="Placa selecionada"
              className={style["placa-icon"]}
            />
            <div className={style["content-placa"]}>
              <p className={style["text-placa"]}>Placa</p>
              <p className={style["placa"]}>{placa}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ marginRight: "8px" }}>
              <select
                className={style["selecionarTipoRelatorio"]}
                value={selectedValue}
                onChange={handleSelected}
              >
                <option value="">Selecione uma opção</option>
                {options?.map((option: Array<any>, index: number) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <FiltroData onFilter={handleFilterData} />
            <div className={style.filtrosDatagrid}>
              <GridToolbarDensitySelector />
              <ExportButton />
            </div>
          </div>
        </GridToolbarContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          {inicio && fim ? (
            <>
              <p
                style={{
                  color: styleGeral.Corsecundaria,
                  marginBottom: "10px",
                  marginRight: "5px",
                }}
              >
                <b>Intervalo da pesquisa:</b>
              </p>
              <p style={{ color: "white", marginBottom: "10px" }}>
                {tsParaData(inicio)} à {tsParaData(fim)}
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  useEffect(() => {
    const recursiveKeys = (obj: any): string[] => {
      let keys: string[] = [];
      for (const key in obj) {
        if (key !== "data") {
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !Array?.isArray(obj[key])
          ) {
            keys = keys?.concat(recursiveKeys(obj[key]));
          } else {
            keys?.push(key);
          }
        }
      }
      return keys;
    };

    const columnKeys = Array.from(new Set(recursiveKeys(relatorio))).map(
      (key) => key.replace(/\s/g, "_")
    );

    const updatedColumns = columnKeys.map((columnName: string) => {
      const renamedColumn = renameColumns[columnName] || columnName;
      const headerName = renamedColumn.replace(/_/g, " ");

      return {
        field: columnName,
        headerName: headerName?.toUpperCase(),
        width: 350,
        renderCell: (params: any) => {
          const value = params?.value !== undefined ? params?.value : " ";
          const tooltipTitle = String(value);
          return (
            <CustomTooltip title={tooltipTitle}>
              <span
                style={{
                  textTransform: "uppercase",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {value}
              </span>
            </CustomTooltip>
          );
        },
      };
    });

    const dataIndex = updatedColumns.findIndex(
      (column: GridColDef) => column.field === "ts"
    );

    const dataColumn = updatedColumns.splice(dataIndex, 1)[0];
    updatedColumns.unshift(dataColumn);
    setColumns(updatedColumns);

    const updatedRows = relatorioComUnderscores.map(
      (dados: any, index: number) => {
        const row: any = { id: index };
        columnKeys.forEach((columnName: string) => {
          let valor = dados[columnName] || "";
          if (Array.isArray(valor)) {
            valor = valor.filter(function (str) {
              return !str.includes("Watchdog");
            });
            row[columnName] = valor.join(", ");
          }

          if (columnName !== "ALARMES_DO_SISTEMA") {
            if (
              columnName.includes("HORAS") ||
              columnName.includes("HORÍMETRO")
            ) {
              row[columnName] = valor.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              row[columnName] = valor;
            }
          }
        });
        return row;
      }
    );

    setRows(updatedRows);
  }, [relatorio]);

  const linhaSemValor: GridRowsProp = [
    { id: "1", nome: "Intervalo de data sem valores." },
  ];

  const colunaSemValor: GridColDef[] = [
    {
      field: "nome",
      headerName: "Intervalo de data sem valores.",
      flex: 1,
    },
  ];

  function compararPorTimestamp(a: any, b: any) {
    return b?.ts - a?.ts;
  }

  const rowsOrdenadas = useMemo(() => {
    if (rows && rows.length > 0) {
      return [...rows].sort(compararPorTimestamp);
    }
    return rows;
  }, [rows]);

  let rowsConvertidas = rowsOrdenadas.map((row: any) => {
    return {
      ...row,
      ts: timestampParaDataEHora(row.ts),
    };
  });

  return (
    <div style={{ height: 415, width: "100%" }}>
      <div className={style.tabela}>
        <DataGrid
          rows={rowsConvertidas?.length > 0 ? rowsConvertidas : linhaSemValor}
          columns={
            columns?.length > 0
              ? columns[0] !== undefined
                ? columns
                : colunaSemValor
              : colunaSemValor
          }
          disableRowSelectionOnClick
          loading={isLoading}
          density="compact"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: FiltroRelatorio,
          }}
          sx={sxMUI}
        />
      </div>
    </div>
  );
}
