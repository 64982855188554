import Input from "../../Components/Input/index";
import { Button } from "../../Components/Button";
import style from "./Check-List.module.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import api from "../../Services/Api";
import { showDynamicToast } from "../../Components/Show-Toast";

let valorCentral: string | number;
let valorDireita: string | number;
let valorEsquerda: string | number;

export default function CheckListConsumo() {
  const operador = useSelector((state: any) => state.usuario);
  const infoCheckList = useSelector((state: any) => state.InfoCheckList);
  let placa = infoCheckList.placa;
  const dados = useSelector((state: any) => state.dados);

  if (Array.isArray(dados)) {
    dados?.map((dados: any) => {
      dados?.valores?.map((valores: any) => {
        if (valores?.nome === "Horas Operacao Vassoura Principal") {
          valorCentral = valores?.valor;
        } else if (valores?.nome === "Horas Operacao Vassoura Direita") {
          valorDireita = valores?.valor;
        } else if (valores?.nome === "Horas Operacao Vassoura Esquerda") {
          valorEsquerda = valores?.valor;
        }
      });
    });
  }

  const [vassouraCentralHorimetro, setvassouraCentralHorimetro] = useState();
  const [vassouraCentralQtSeguimentos, setvassouraCentralQtSeguimentos] =
    useState();
  const [vassouraDireitaHorimetro, setvassouraDireitaHorimetro] = useState();
  const [vassouraDireitaQtSeguimentos, setvassouraDireitaQtSeguimentos] =
    useState();
  const [vassouraEsquerdaHorimetro, setvassouraEsquerdaHorimetro] = useState();
  const [vassouraEsquerdaQtSeguimentos, setvassouraEsquerdaQSeguimentos] =
    useState();

  const handlevassouraCentralHorimetro = (e: any) => {
    setvassouraCentralHorimetro(e.target.value);
  };
  const handlevassouraCentralSeguimentos = (e: any) => {
    setvassouraCentralQtSeguimentos(e.target.value);
  };
  const handlevassouraLateralDireitaHorimetro = (e: any) => {
    setvassouraDireitaHorimetro(e.target.value);
  };
  const handlevassouraLateralDireitaNumeroVassoura = (e: any) => {
    setvassouraDireitaQtSeguimentos(e.target.value);
  };
  const handlevassouraLateralEsquerdaHorimetro = (e: any) => {
    setvassouraEsquerdaHorimetro(e.target.value);
  };
  const handlevassouraLateralEsquerdaNumeroVassoura = (e: any) => {
    setvassouraEsquerdaQSeguimentos(e.target.value);
  };

  let obj = {
    operador: operador,
    placa: placa,
    vassoura_central: {
      horimetro: vassouraCentralHorimetro,
      qt_seguimentos: vassouraCentralQtSeguimentos,
    },
    vassoura_lat_direita: {
      horimetro: vassouraDireitaHorimetro,
      qt_seguimentos: vassouraDireitaQtSeguimentos,
    },
    vassoura_lat_esquerda: {
      horimetro: vassouraEsquerdaHorimetro,
      qt_seguimentos: vassouraEsquerdaQtSeguimentos,
    },
  };

  const Enviar = () => {
    api
      .post(`/check-list-consumo-vassouras`, obj)
      .then(() => {
        showDynamicToast("success", "Dados enviados com sucesso!");
      })
      .catch(() => {
        showDynamicToast("error", "Erro ao enviar os dados!");
      });
  };

  return (
    <div className={style.div}>
      <div>
        <div className={style.tilte}>
          <p className={style.tilteText}>CONSUMO DAS VASSOURAS</p>
        </div>
        <div className={style.subTilte}>VASSOURA CENTRAL ( H )</div>
        <div>
          <Input
            className="vassoura"
            onChange={handlevassouraCentralHorimetro}
            type="text"
            value={valorCentral ? valorCentral : "---"}
            disabled={true}
          />
          <Input
            className="vassoura"
            onChange={handlevassouraCentralSeguimentos}
            type="number"
            placeholder="Digite a Quant. Seguimentos"
          />
        </div>
        <div className={style.subTilte}>VASSOURA LATERAL DIREITA ( H )</div>
        <div>
          <Input
            className="vassoura"
            onChange={handlevassouraLateralDireitaHorimetro}
            type="text"
            value={valorDireita ? valorDireita : "---"}
            disabled={true}
            placeholder="Digite o Horimetro Varredeira"
          />
          <Input
            className="vassoura"
            onChange={handlevassouraLateralDireitaNumeroVassoura}
            type="number"
            placeholder="Digite o nº da Vassoura"
          />
        </div>
        <div className={style.subTilte}>VASSOURA LATERAL ESQUERDA ( H )</div>
        <div>
          <Input
            className="vassoura"
            onChange={handlevassouraLateralEsquerdaHorimetro}
            type="text"
            placeholder="Digite o Horimetro Varredeira"
            value={valorEsquerda ? valorEsquerda : "---"}
            disabled={true}
          />
          <Input
            className="vassoura"
            onChange={handlevassouraLateralEsquerdaNumeroVassoura}
            type="number"
            placeholder="Digite o nº da Vassoura"
          />
        </div>
      </div>
      <Button onClick={Enviar} texto="ENVIAR" className="EnviarVassouras" />
    </div>
  );
}
