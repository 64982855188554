import React, { useState } from "react";
import AuthCode from "react-auth-code-input";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../Components/Button";
import { setEtapa } from "../../../../Redux/Actions/index";
import api from "../../../../Services/Api";
import { InputSenha } from "./Components/input";
import style from "./NovaSenha.module.scss";
import { showDynamicToast } from "../../../../Components/Show-Toast";

let novaSenha: any;
let ConfirmarSenha: any;
let code: any = " ";
export function CriarNovaSenha() {
  const etapa = useSelector((state: any) => state.etapa);
  const email = useSelector((state: any) => state.email);
  const dispatch = useDispatch();

  const [errSenha, setErrSenha] = useState("none");
  const [disabled, setDisabled] = useState(true);

  function validarSenha(senha: any) {
    let re = /^(?=.*[A-Z])(?=.*[!#@$%&])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;
    re.test(senha);
    if (novaSenha === ConfirmarSenha) {
      setDisabled(re.test(senha) === true ? false : true);
    } else {
      setDisabled(true);
    }
  }
  const handleOnChange = (res: any) => {
    code = res;
  };

  const handleNovaSenha = (e: any) => {
    novaSenha = e.target.value;
  };

  const handleConfirmeSenha = (e: any) => {
    ConfirmarSenha = e.target.value;
    validarSenha(ConfirmarSenha);
  };

  const RedefinirSenha = () => {
    if (code !== " " && novaSenha === ConfirmarSenha) {
      setErrSenha("none");

      let obj = {
        confirm_code: code,
        email: email,
        new_password: ConfirmarSenha,
      };
      api
        .post(`/confirm-forgot-password`, obj)
        .then((response: any) => {
          if (response.request.status === 200) {
            dispatch(setEtapa(etapa));
          }
        })
        .catch((err: any) => {
          if (err.request.status === 400) {
            showDynamicToast("info", "Preencha todos os campos obrigatórios!");
          } else if (err.request.status === 401) {
            showDynamicToast("error", "Código/senha inválidos!");
          } else if (err.request.status === 402) {
          } else if (err.request.status === 500) {
            showDynamicToast("error", "Código/senha inválidos!");
          }
        });
    } else {
      setErrSenha("block");
    }
  };

  return (
    <div className={style.code}>
      <p className={style.texto}>
        Por favor, digite o código de verificação que você recebeu por e-mail.
      </p>
      <AuthCode
        length={6}
        inputClassName={style.input}
        onChange={handleOnChange}
      />
      <div className={style.divSenha}>
        <p className={style.textoSenha}>Digite sua nova senha:</p>
        <InputSenha place={"Nova senha"} onChange={handleNovaSenha} />
        <p className={style.textoSenhaConfirmar}>Confirme sua senha:</p>
        <InputSenha place="Confirme sua senha" onChange={handleConfirmeSenha} />
        <p style={{ display: `${errSenha}` }} className={style.aviso}>
          As senhas não são iguais. Tente novamente.
        </p>
        <Button
          disabled={disabled}
          onClick={RedefinirSenha}
          texto="REDEFINIR SENHA"
          className="confirmarEmail"
        />
      </div>
    </div>
  );
}
