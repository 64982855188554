const initialState = {
  email: "",
  senha: "",
  etapa: 0,
  dados: "",
  usuario: "",
  videos: "",
  videoAtual: "",
  InfoCheckList: "",
  whoami: "",
  placa: "",
  rows: []
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_SENHA":
      return { ...state, senha: action.payload };
    case "SET_ETAPA":
      return { ...state, etapa: (action.payload += 1) };
    case "SET_INFOSHORASTRABALHADAS":
      return { ...state, dados: action.payload };
    case "SET_USUARIO":
      return { ...state, usuario: action.payload };
    case "SET_VIDEOS":
      return { ...state, videos: action.payload };
    case "SET_VIDEOATUAL":
      return { ...state, videoAtual: action.payload };
    case "SET_CHECKLIST":
      return { ...state, InfoCheckList: action.payload };
      case "SET_WHOAMI":
      return { ...state, whoami: action.payload };
      case "SET_PLACA":
        return { ...state, placa: action.payload };
      case "SET_LISTAGEMCHECKLIST":
        return { ...state, rows: action.payload };
    default:
      return state;
  }
};

export default reducer;
