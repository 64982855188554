import Input from "../../Components/Input";
import { DataAtual, aplicarCapitalize } from "../../Services/Utils";
import style from "./Check-List.module.scss";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InputRadio from "./Components/Input-Radio";
import { Varredeira, Veiculo } from "./functions";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Cronometro from "./Components/Cronometro";
import { Button } from "../../Components/Button";
import Swal from "sweetalert2";
import api from "../../Services/Api";
import { setCheckList } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { styleGeral } from "../../Styles/Cores";
import { showDynamicToast } from "../../Components/Show-Toast";
import Carregando from "../../Assets/Gif/loading.gif";
import Select from "react-select";
import opcoesTurnos from "./Turnos.json";

type OptionType = {
  label: string;
  value: string;
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    fontSize: "16px",
  },
});

let objInputs: any;

export default function CheckList() {
  const dispatch = useDispatch();
  let arrayVeiculo: Array<any> = [];
  let arrayVarredeira: Array<any> = [];
  let validacao: boolean = false;
  const navigate = useNavigate();

  const [contrato, setContrato] = useState<any>();
  const [operador, setOperador] = useState<any>();
  const [equipamento, setEquipamento] = useState<any>();
  const [placas, setPlacas] = useState<string[]>([]);
  const [placa, setPlaca] = useState<any>();
  const [turno, setTurno] = useState<any>();
  const [carregando, setCarregando] = useState(false);

  let infoInputs = {
    contrato: aplicarCapitalize(contrato),
    operador: aplicarCapitalize(operador),
    equipamento: aplicarCapitalize(equipamento),
    placa: placa?.toUpperCase(),
    turno: aplicarCapitalize(turno),
  };

  const handleRadioVeiculo = (e: any) => {
    let identificador = e.target.id;
    const buscar = document.getElementById(identificador);
    const id = buscar?.querySelector("td:first-child")?.innerHTML;
    const texto = buscar?.querySelector("td:nth-child(2)")?.innerHTML;

    let linha: any = {
      item: id,
      nome: texto,
      situacao: e.target.value,
    };

    const objetoExistenteIndex = arrayVeiculo.findIndex(
      (objeto) => objeto.item === linha.item
    );

    if (objetoExistenteIndex !== -1) {
      arrayVeiculo[objetoExistenteIndex].situacao = e.target.value;
    } else {
      arrayVeiculo.push(linha);
    }

    let quantidadeNC = 0;

    ["1", "2", "3", "5", "6", "7", "10", "12", "17"].forEach((item) => {
      const itemVeiculo = arrayVeiculo.find((objeto) => objeto.item === item);
      if (itemVeiculo && itemVeiculo.situacao === "nc") {
        quantidadeNC++;
      }
    });

    if (
      quantidadeNC === ["1", "2", "3", "5", "6", "7", "10", "12", "17"].length
    ) {
      Swal.fire({
        html: `<b>Itens de Verificação do Veículo (Caminhão)</b>
               <p style="margin-top: 25px"><b>NOTA 01:</b> Em caso de NC dos itens 01, 02, 03, 05, 06, 07, 10, 12, 17 operar somente após tratativa.</p>
              `,
        icon: "warning",
        iconColor: "#9de0f6",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          icon: style.iconPopup,
          popup: style.popup,
        },
      });
      setTimeout(function () {
        Swal.close();
        navigate("/iniciar-Check-list");
      }, 5000);
    }

    if (arrayVeiculo.length > 17) {
      validacao = false;
    } else {
      validacao = true;
    }
  };

  const handleRadioVarredeira = (e: any) => {
    let identificador = e.target.id;
    const buscar = document.getElementById(identificador);
    const id = buscar?.querySelector("td:first-child")?.innerHTML;
    const texto = buscar?.querySelector("td:nth-child(2)")?.innerHTML;

    let linha: any = {
      item: id,
      nome: texto,
      situacao: e.target.value,
    };

    const objetoExistenteIndex = arrayVarredeira.findIndex(
      (objeto) => objeto.item === linha.item
    );

    if (objetoExistenteIndex !== -1) {
      arrayVarredeira[objetoExistenteIndex].situacao = e.target.value;
    } else {
      arrayVarredeira.push(linha);
    }

    let quantidadeNC = 0;

    ["1", "2", "3", "6", "9"].forEach((item) => {
      const itemVeiculo = arrayVarredeira.find(
        (objeto) => objeto.item === item
      );
      if (itemVeiculo && itemVeiculo.situacao === "nc") {
        quantidadeNC++;
      }

      if (arrayVarredeira.length > 14) {
        validacao = false;
      } else {
        validacao = true;
      }
    });

    if (quantidadeNC === ["1", "2", "3", "6", "9"].length) {
      Swal.fire({
        html: `<b>Itens de Verificação da Varredeira</b>
               <p style="margin-top: 25px"><b>NOTA 02:</b> Em caso de NC dos itens 01, 02, 03, 06, 09 operar somente após tratativa.</p>
              `,
        icon: "warning",
        iconColor: "#9de0f6",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          icon: style.iconPopup,
          popup: style.popup,
        },
      });
      setTimeout(function () {
        Swal.close();
        navigate("/iniciar-Check-list");
      }, 5000);
    }

    const novoArray = arrayVeiculo.concat(arrayVarredeira);

    objInputs = {
      contrato: aplicarCapitalize(contrato),
      operador: aplicarCapitalize(operador),
      equipamento: aplicarCapitalize(equipamento),
      placa: placa?.toUpperCase(),
      turno: aplicarCapitalize(turno),
      itens_verificacao: novoArray,
    };
  };

  const handleEnviar = () => {
    if (
      validacao === false &&
      contrato &&
      operador &&
      equipamento &&
      placa &&
      turno
    ) {
      setCarregando(true);
      if (infoInputs !== undefined) {
        dispatch(setCheckList(infoInputs));
      }
      api
        .post(`/check-list-pre-uso`, objInputs)
        .then(() => {
          showDynamicToast(
            "success",
            "Check List finalizado com sucesso, você será redirecionado!"
          );
          setCarregando(false);
          setTimeout(function () {
            navigate("/home");
          }, 4000);
        })
        .catch(() => {
          setCarregando(false);
          showDynamicToast("error", "Houve um erro ao finalizar o Check List!");
          setTimeout(function () {
            navigate("/iniciar-Check-list");
          }, 4000);
        });
    } else {
      showDynamicToast(
        "info",
        "Por favor, preencha todos os campos antes de enviar o Check List!"
      );
    }
  };

  const turnoEscolhido = (option: OptionType | null) => {
    if (option) {
      setTurno(option.value);
    }
  };

  const selectConfigPermissoes = {
    options: opcoesTurnos,
    placeholder: "Turno",
    value: turno?.value,
    getOptionLabel: (option: OptionType) => option.label,
    getOptionValue: (option: OptionType) => option.value,
  };

  useEffect(() => {
    api
      .get(`/listar-placas-telemetria`)
      .then((response: any) => {
        let arrayPlacas: any = [];
        response.data.map((dados: any) => {
          arrayPlacas.push(dados);
        });
        setPlacas(arrayPlacas);
      })
      .catch(() => {
        showDynamicToast(
          "error",
          "Erro interno do servidor. Tente novamente após alguns minutos ou contate o suporte se o problema continuar."
        );
      });
  }, []);

  let options = placas?.map((placa: string) => ({
    label: placa,
    value: placa,
  }));

  const selectConfigPermissoesPlaca = {
    options: options,
    placeholder: "Placas...",
    value: placa?.value,
    getOptionLabel: (option: OptionType) => option.label,
    getOptionValue: (option: OptionType) => option.value,
  };

  const placaEscolhida = (option: OptionType | null) => {
    if (option) {
      setPlaca(option.value);
    }
  };

  return (
    <div className={style.all}>
      <div className={style.inputInfos}>
        <Input
          type="text"
          placeholder="Contrato"
          onChange={(e: any) => setContrato(e.target.value)}
          className="checkList"
        />
        <Input
          type="text"
          placeholder="Operador"
          onChange={(e: any) => setOperador(e.target.value)}
          className="checkList"
        />
        <Input
          type="text"
          placeholder="Equipamento"
          onChange={(e: any) => setEquipamento(e.target.value)}
          className="checkList"
        />
        <Select
          className={style["select"]}
          {...selectConfigPermissoesPlaca}
          onChange={placaEscolhida}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
              fontSize: 14,
            }),
          }}
          isLoading={placas.length === 0}
        />
        <Select
          className={style["select"]}
          {...selectConfigPermissoes}
          onChange={turnoEscolhido}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
              fontSize: 14,
            }),
          }}
        />
        <CalendarMonthOutlinedIcon
          sx={{ color: "white", fontSize: "30px", marginLeft: "1%" }}
        />
        <div className={style.data}>
          <DataAtual />
        </div>
      </div>
      <div className={style.tilte}>
        <p>Inspeção Pré-Uso | Check-List do Equipamento</p>
      </div>
      <div className={style.legenda}>
        <div>
          <p>
            <b>Legenda: </b>
            <b>OK</b> - Conforme | <b>NC</b> - Não conforme
          </p>
        </div>
        <div className={style.cronometro}>
          <TimerOutlinedIcon
            sx={{ fontSize: "30px", color: styleGeral.Corsecundaria }}
          />
          <Cronometro />
        </div>
      </div>
      <table className={style.tabela}>
        <thead>
          <tr className={style.primeiraLista}>
            <th className={style.tiltePrimeiraLista}>Item</th>
            <th className={style.tiltePrimeiraLista}>
              Itens de Verificação do Veículo (Caminhão)
            </th>
            <th className={style.tiltePrimeiraLista}>Situação</th>
          </tr>
        </thead>
        <tbody>
          {Veiculo.map((dados: any) => (
            <tr key={dados.id} id={dados.id}>
              <td className={style.linhas}>{dados.key}</td>
              <NoMaxWidthTooltip title={dados.descricao}>
                <td className={style.linhas}>{dados.descricao}</td>
              </NoMaxWidthTooltip>
              <td>
                <div className={style.situacoes}>
                  <InputRadio
                    className={style.inputRadio}
                    type="radio"
                    name={`situacao_${dados.id}`}
                    value="ok"
                    id={dados.id}
                    onChange={handleRadioVeiculo}
                  />
                  <p>OK</p>

                  <InputRadio
                    type="radio"
                    name={`situacao_${dados.id}`}
                    value="nc"
                    id={dados.id}
                    onChange={handleRadioVeiculo}
                  />
                  <p>NC</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className={style.tabela}>
        <thead>
          <tr className={style.primeiraLista}>
            <th className={style.tiltePrimeiraLista}>Item</th>
            <th className={style.tiltePrimeiraLista}>
              Itens de Verificação da Varredeira
            </th>
            <th className={style.tiltePrimeiraLista}>Situação</th>
          </tr>
        </thead>
        <tbody>
          {Varredeira.map((dados: any) => (
            <tr key={dados.id} id={dados.id}>
              <td className={style.linhas}>{dados.key}</td>
              <NoMaxWidthTooltip title={dados.descricao}>
                <td className={style.linhas}>{dados.descricao}</td>
              </NoMaxWidthTooltip>
              <td>
                <div className={style.situacoes}>
                  <InputRadio
                    type="radio"
                    name={`situacao_${dados.id}`}
                    value="ok"
                    id={dados.id}
                    onChange={handleRadioVarredeira}
                  />
                  <p>OK</p>

                  <InputRadio
                    className={style.inputRadio}
                    type="radio"
                    name={`situacao_${dados.id}`}
                    value="nc"
                    id={dados.id}
                    onChange={handleRadioVarredeira}
                  />
                  <p>NC</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {!carregando ? (
        <Button className="checkList" onClick={handleEnviar} texto="Enviar" />
      ) : (
        <img src={Carregando} alt="Carregando" className={style.carregando} />
      )}
    </div>
  );
}
